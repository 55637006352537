import NProgress from 'nprogress';
import { useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
// material
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, GlobalStyles } from '@mui/material';
//
import LogoYouwho from './LogoYouwho';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export function ProgressBarStyle() {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '#nprogress': {
          pointerEvents: 'none',
          '& .bar': {
            top: 0,
            left: 0,
            height: 2,
            width: '100%',
            position: 'fixed',
            zIndex: theme.zIndex.snackbar,
            backgroundColor: theme.palette.primary.main,
            boxShadow: `0 0 2px ${theme.palette.primary.main}`
          },
          '& .peg': {
            right: 0,
            opacity: 1,
            width: 100,
            height: '100%',
            display: 'block',
            position: 'absolute',
            transform: 'rotate(3deg) translate(0px, -4px)',
            boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
          }
        }
      }}
    />
  );
}

function ProgressBar() {
  NProgress.configure({
    showSpinner: false
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return null;
}

export default function LoadingScreen({ ...other }) {
  return (
    <>
      <ProgressBar />

      <RootStyle {...other} sx={{ backgroundColor: 'initial' }}>
        <motion.div
          initial={{ scale: 1.2 }}
          animate={{ scale: [1.1, 1.2] }}
          transition={{
            duration: 0.2,
            ease: 'easeInOut',
            repeatDelay: 2.8,
            repeat: Infinity
          }}
        >
          <LogoYouwho varsIn="" sx={{ width: 80, height: 80 }} sxLogo={{ width: 80, height: 80 }} />
        </motion.div>

        <Box
          component={motion.div}
          animate={{
            scale: [1, 2.5],
            opacity: [1, 0]
          }}
          transition={{
            ease: 'linear',
            duration: 3,
            repeat: Infinity
          }}
          sx={{
            width: 120,
            height: 120,
            borderRadius: '50%',
            position: 'absolute',
            border: (theme) => `solid 8px ${alpha(theme.palette.primary.main, 1)}`
          }}
        />

        <Box
          component={motion.div}
          animate={{
            scale: [1, 2],
            opacity: [1, 0],
            borderWidth: ['8px', '6px']
          }}
          transition={{ ease: 'linear', duration: 3, repeat: Infinity }}
          sx={{
            width: 120,
            height: 120,
            borderRadius: '50%',
            position: 'absolute',
            border: (theme) => `solid 8px ${alpha(theme.palette.grey['500'], 1)}`
          }}
        />
      </RootStyle>
    </>
  );
}
