// ----------------------------------------------------------------------

const shape = {
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16,
  borderRaidus12: '12px'
};

export default shape;
