import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Stack } from '@mui/material';

// ----------------------------------------------------------------------

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.grey['600'],
  marginRight: theme.spacing(10),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shortest
  }),
  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.primary.main
  }
}));

// ----------------------------------------------------------------------

IconBullet.propTypes = {
  type: PropTypes.oneOf(['subheader', 'item'])
};

function IconBullet({ type = 'item' }) {
  return (
    <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
      <Box
        component="span"
        sx={{
          ml: '2px',
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'currentColor',
          ...(type !== 'item' && { ml: 0, width: 8, height: 2, borderRadius: 2 })
        }}
      />
    </Box>
  );
}

MenuDesktopItem.propTypes = {
  item: PropTypes.object
};

function MenuDesktopItem({ item }) {
  const { title, path } = item;

  return (
    <>
      {title === 'Home' || title === 'Vesting Schedule' || title === 'How To Buy' ? (
        <LinkStyle
          href={path}
          sx={{
            // ...(isHome && { color: 'grey.600' }),
            // ...(isOffset && { color: 'text.primary' }),
            '&.active': {
              color: 'primary.main'
            }
          }}
          target="_blank"
        >
          {title}
        </LinkStyle>
      ) : (
        <LinkStyle
          to={path}
          component={RouterLink}
          end={path === '/'}
          sx={{
            // ...(isHome && { color: 'text.primary' }),
            // ...(title === 'ICO Portal' && { color: 'primary.main' }),
            '&.active': {
              color: 'primary.main'
            }
          }}
        >
          {title}
        </LinkStyle>
      )}
    </>
  );
}

MenuDesktop.propTypes = {
  isOffset: PropTypes.bool,
  isHome: PropTypes.bool,
  navConfig: PropTypes.array
};

export default function MenuDesktop({ isOffset, isHome, navConfig }) {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack direction="row">
      {navConfig.map((link) => (
        <MenuDesktopItem
          key={link.title}
          item={link}
          isOpen={open}
          onOpen={handleOpen}
          onClose={handleClose}
          isOffset={isOffset}
          isHome={isHome}
        />
      ))}
    </Stack>
  );
}
