// import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// moralis
import { Moralis } from '../rootReducer';
// dispatch
import { dispatch } from '../store';
// ico/evm related
import ERC20 from '../../abis/ERC20.json';
import YouIco from '../../abis/YouIco.json';
import TokenStaking from '../../abis/TokenStaking.json';
import TokenVesting from '../../abis/TokenVesting.json';
import { chainInfo } from '../../assets/ChainLogos';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  erc20: ERC20,
  icoAbi: YouIco,
  tokenStaking: TokenStaking,
  tokenVesting: TokenVesting,
  chainInfo,
  icoContractAddress: process.env.REACT_APP_ICO_ADDRESS, // 0x4Dabc90301737c569314baabF6bc5dAfE1b90464 testnet ico address '0x46F591d4E70e7E5dC9bc0FB2b96863630410BC56', // mainnet ico address 0xb14eA83dcf28b95C6767A90CCf2803ea275553e3 local
  icoSettings: null,
  icoProvider: '', // 'walletconnect'
  bnbPrice: 400,
  youPrice: 0.0004,
  networkId: 56,
  boughtTokensClass: 'BoughtTokens',
  // stakingWalletClass: 'StakeWallet',
  // unstakingWalletClass: 'Unstake',
  // withdrawWalletClass: 'WithdrawStake',
  // tokensClass: 'TokenDetails',
  tokenDetails: [],
  vestingWalletClass: 'VestingWallet',
  icoPrice: '0.00036'
};

const slice = createSlice({
  name: 'ico',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ICO SETTINGS
    getIcoSettingsSuccess(state, action) {
      state.isLoading = false;
      state.icoSettings = action.payload;
    },

    // SET ICO CONTRACT ADDRESS WHEN CHANGING NETWORKS
    setIcoContractAddressSuccess(state, action) {
      state.isLoading = false;
      state.icoContractAddress = action.payload;
    },

    // SET CURRENT NETWORK ID
    setNetworkIdSuccess(state, action) {
      state.isLoading = false;
      state.networkId = action.payload;
    },

    // SET ICO PROVIDER for WEB3
    setIcoProviderSuccess(state, action) {
      state.isLoading = false;
      state.icoProvider = action.payload;
    },

    // GET BNB PRICE FROM PANCAKESWAPV2
    getBnbPriceSuccess(state, action) {
      state.isLoading = false;
      state.bnbPrice = action.payload;
    },

    // GET YOU PRICE FROM PANCAKESWAPV2
    getYouPriceSuccess(state, action) {
      state.isLoading = false;
      state.youPrice = action.payload;
    },

    // SET CURRENT YOU PRICE
    setIcoPriceSuccess(state, action) {
      state.isLoading = false;
      state.icoPrice = action.payload;
    },

    // SET STAKING TOKEN DETAILS
    setTokenDetailsSuccess(state, action) {
      state.isLoading = false;
      state.tokenDetails = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function getIcoSettings() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const query = new Moralis.Query('IcoSettings');
      const response = await query.first();
      // console.log('response from getIcoSettings action', response.attributes);
      dispatch(slice.actions.getIcoSettingsSuccess(response.attributes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setIcoContractAddress(contractAddress, netId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setIcoContractAddressSuccess(contractAddress));
      dispatch(slice.actions.setNetworkIdSuccess(netId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setIcoProvider(provider) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setIcoProviderSuccess(provider));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBnbPrice() {
  const options = {
    address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    chain: 'bsc',
    exchange: 'PancakeSwapv2'
  };

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const price = await Moralis.Web3API.token.getTokenPrice(options);
      console.log('bnbPrice', price);
      dispatch(slice.actions.getBnbPriceSuccess(price.usdPrice));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getYouPrice() {
  const options = {
    address: '0xB583961E033Dfe0FfF161952f7BA21c411b6103d',
    chain: 'bsc',
    exchange: 'PancakeSwapv2'
  };

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const price = await Moralis.Web3API.token.getTokenPrice(options);
      console.log('youPrice', price);
      dispatch(slice.actions.getYouPriceSuccess(price.usdPrice));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setIcoPrice(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setIcoPriceSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setTokenDetailsR(data, addresses) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('data', data);
      console.log('addresses', addresses);
      if (addresses && addresses.length > 0) {
        const addressArray = [];
        console.log('running setTokenDetailsR');
        for (let i = 0; i < addresses.length; i += 1) {
          console.log(1, i);
          const obj = data.find((e) => e.attributes.tokenAddress.toLowerCase() === addresses[i].address.toLowerCase());
          console.log(2, i);
          const objA = JSON.parse(JSON.stringify(obj.attributes));
          console.log(3, i);
          objA.rateConverter = addresses[i].rateConverter ? addresses[i].rateConverter : undefined;
          console.log(4, i);
          addressArray.push(objA);
          console.log(5, i);
        }
        console.log('addressArray', addressArray);
        dispatch(slice.actions.setTokenDetailsSuccess(addressArray));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
