import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import icoReducer from './slices/ico';
// config
import { moralisAppId, moralisServerUrl } from '../config';
// moralis
const Moralis = require('moralis-v1');

// ----------------------------------------------------------------------

const serverUrl = moralisServerUrl;
const appId = moralisAppId;
Moralis.start({ serverUrl, appId });

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  version: 1,
  whitelist: []
};

// const userPersistConfig = {
//   key: 'user',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: []
// };

const rootReducer = combineReducers({
  user: userReducer,
  // user: persistReducer(userPersistConfig, userReducer),
  ico: icoReducer
});

export { rootPersistConfig, rootReducer, Moralis };
