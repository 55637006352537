import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import dayjs from 'dayjs';
// moralis
import { useChain, useMoralis } from 'react-moralis';
// material
import { motion } from 'framer-motion';
import { Card, Box, Button, Typography, Stack, Grid, Divider } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme, alpha } from '@mui/material/styles';
// redux
import { useSelector } from 'react-redux';
import IcoProgressBar from '../../../IcoProgressBar';
// components
import AlertMessage from '../../../../utils/AlertMessage';

// ----------------------------------------------------------------------

const HeroVideoStyle = styled('video')({
  width: '100%',
  minHeight: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const GridCard = styled(Card)(({ theme }) => ({
  padding: '20px',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius
}));

const GridStack = styled(Stack)(() => ({
  padding: '40px',
  justifyContent: 'center',
  alignItems: 'center'
}));

// ----------------------------------------------------------------------

export default function Overview() {
  const navigate = useNavigate();
  const { icoSettings } = useSelector((state) => state.ico);
  const [icoEndDate, setIcoEndDate] = useState(new Date('WedSaturday, March 5, 2022 5:00:00 AM'));
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState('Video no yet available. Please check again soon.');
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    const start = dayjs(Number(icoSettings.start) + 1000);
    // console.log(icoSettings.start);
    // console.log(start);
  }, [icoSettings]);

  return (
    <>
      <AlertMessage open={openError} setOpen={setOpenError} message={errorMessage} severity="error" />

      <Stack p={{ xs: '20px', sm: '10px' }}>
        <Stack spacing={2} alignItems="center">
          <Box sx={{ height: { xs: 75, sm: 100 } }}>
            <img alt="YOUWHO logo" src="/static/logos/svg/youwho-teal_text-long-alt.svg" width={570} />
          </Box>
          <Stack direction="row" spacing={1}>
            <Typography variant="h2" fontWeight="300">
              ICO Portal
            </Typography>
            <Typography variant="h2">OPEN</Typography>
          </Stack>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => navigate('../invest')}
            endIcon={<Icon icon="fluent:arrow-right-16-filled" width={20} height={20} />}
            sx={{
              height: 56,
              fontSize: { xs: '1rem', sm: '1.2rem' },
              width: { xs: '100%', sm: '400px' }
            }}
          >
            Continue To Invest
          </Button>
        </Stack>
        {true && (
          <>
            <Box sx={{ height: '30px' }} />
            <Divider light variant="middle" sx={{ width: '90%', mx: 'auto', my: '30px' }} />
            <Box>
              <Typography variant="h3" textAlign="center">
                Video Guides
              </Typography>
            </Box>
            <Grid
              container
              justifyContent="center"
              alignItems="stretch"
              sx={{ paddingTop: '30px', paddingBottom: '10px' }}
            >
              {/* <Grid item xs={12} sm={6} sx={{ padding: '10px' }}>
                <GridCard
                  component={Button}
                  color="error"
                  href="#na"
                  onClick={() => setOpenError(true)}
                  // target="blank"
                  sx={{ backgroundColor: theme.palette.grey[200] }}
                >
                  <Box p="0px" py="20px">
                    <Stack flexDirection="row" justifyContent="space-between">
                      <img alt="mastercard logo" src="/static/icons/mastercard-2.svg" width={100} height={150} />
                      <img alt="visa logo" src="/static/icons/visa.svg" height={150} />
                    </Stack>
                  </Box>
                  <Typography variant="p" textAlign="center" color={theme.palette.grey[700]}>
                    Invest with Credit Card
                  </Typography>
                  <HeroVideoStyle />
                </GridCard>
              </Grid> */}
              <Grid item xs={12} sm={6} sx={{ padding: '10px' }}>
                <GridCard
                  component={Button}
                  color="secondary"
                  href="https://youtu.be/IHbJwezwbK0"
                  onClick={() => {}}
                  target="blank"
                  sx={(theme) => ({ backgroundColor: alpha(theme.palette.secondary.main, 0.2) })}
                >
                  <Box p="20px">
                    <img alt="metamask logo" src="/static/icons/metamask.svg" height={150} width={120} />
                  </Box>
                  <Typography variant="p" textAlign="center" color="secondary.dark">
                    Invest with Binance Coins (BNB)
                  </Typography>
                  <HeroVideoStyle />
                </GridCard>
              </Grid>
              {/* <Grid item xs={12} sm={4} sx={{ padding: '10px' }}>
                <GridCard
                  component={Button}
                  href="#na"
                  onClick={() => setOpenError(true)}
                  // target="blank"
                  sx={{ backgroundColor: theme.palette.grey[200] }}
                >
                  <Box p="20px">
                    <img alt="metamask logo" src="/static/icons/bitkub.svg" height={150} width={150} />
                  </Box>
                  <Typography variant="p" textAlign="center" color={theme.palette.grey[700]}>
                    วิธีลงทุนด้วย Bitkub + Binance Coin (BNB)
                  </Typography>
                  <HeroVideoStyle />
                </GridCard>
              </Grid> */}
            </Grid>
          </>
        )}

        <Divider light variant="middle" sx={{ width: '90%', mx: 'auto', py: '30px' }} />
        <GridStack sx={{ paddingY: 3, paddingX: { xs: '0px', sm: '20px' } }} spacing={2}>
          <Stack direction="row" spacing={2} id="price">
            <img alt="$YOU logo" src="/static/logos/svg/youwho-logos_token-alt.svg" width={150} />
            <Typography variant="h2"> Pricing</Typography>
          </Stack>
          <IcoProgressBar />
        </GridStack>

        <Box height="20px" />
        <Grid container spacing={2} sx={{ padding: '20px' }}>
          <Grid item xs={12} sm={6}>
            <GridCard>
              <Box>
                <Icon icon="fluent:target-arrow-16-filled" color="#00c999" width="50" height="50" />
              </Box>
              <Typography variant="h4">ICO Target</Typography>
              <Typography variant="h4" fontWeight="300">
                {icoSettings.icoTarget / 1000000000} Bil. ${icoSettings.tokenSymbol}
              </Typography>
            </GridCard>
          </Grid>

          <Grid item xs={12} sm={6}>
            <GridCard>
              <Box>
                <Icon icon="ion:golf" color="#00c999" width="50" height="50" />
              </Box>
              <Typography variant="h4">End date</Typography>
              <Typography variant="h4" fontWeight="300" textAlign="center">
                {icoEndDate.toDateString()}
              </Typography>
            </GridCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GridCard>
              <Box>
                <Icon icon="ion:timer" color="#00c999" width="50" height="50" />
              </Box>
              <Typography variant="h4">Duration</Typography>
              <Typography variant="h4" fontWeight="300">
                {icoSettings.days} days
              </Typography>
            </GridCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GridCard>
              <Box sx={{ p: '2.5px' }}>
                <Icon icon="bi:shield-lock-fill" color="#00c999" width="40" height="40" />
              </Box>
              <Typography variant="h4">Cap per wallet</Typography>
              <Typography variant="h4" fontWeight="300">
                {icoSettings.capPerWallet / 1000000} Mil. ${icoSettings.tokenSymbol}
              </Typography>
            </GridCard>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignSelf="center"
          sx={{ width: { xs: '100%', sm: '400px' }, paddingTop: '50px' }}
        >
          <Button
            size="large"
            sx={{
              height: 56,
              fontSize: { xs: '1rem', sm: '1.2rem' }
            }}
            onClick={() => navigate('../invest')}
          >
            Invest <Icon icon="eva:arrow-ios-forward-fill" />
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
