import React, { useState, useEffect } from 'react';
import {
  useMoralis,
  useMoralisQuery,
  useMoralisSubscription,
  useChain,
  useMoralisWeb3Api,
  useERC20Balances
} from 'react-moralis';
// icons
import { Icon } from '@iconify/react';
// mui
import {
  Button,
  IconButton,
  Typography,
  Stack,
  Snackbar,
  Alert,
  Box,
  useTheme,
  Grid,
  Card,
  Tabs,
  Link,
  Divider,
  Tooltip,
  TextField,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import TabsUnstyled from '@mui/material/Tabs';
import { Tabs as TabsUnstyled } from '@mui/base/Tabs';
// import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import { TabsList as TabsListUnstyled } from '@mui/base/TabsList';
// import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { TabPanel as TabPanelUnstyled } from '@mui/base/TabPanel';
// import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
// import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { Tab as TabUnstyled } from '@mui/base/Tab';
import { alpha, styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import useMediaQuery from '@mui/material/useMediaQuery';
// formik
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// copy
import { CopyToClipboard } from 'react-copy-to-clipboard';
// dayjs
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
// countdown timer
import Countdown from 'react-countdown';
// web3 utils
import Web3 from 'web3';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { setStakingWalletsR, setUnstakeWalletR, setWithdrawHistoryR } from '../../../../store/slices/user';
import { setTokenDetailsR } from '../../../../store/slices/ico';
// helpers
import { getEllipsisTxt } from '../../../../utils/formatters';
// components
import AlertMessage from '../../../../utils/AlertMessage';
import LoadingScreen from '../../../LoadingScreen';
import LoginModal from '../../../forms/LoginModal';

dayjs.extend(relativeTime);
dayjs.extend(duration);

// ---------------------------------------------

const { BN } = Web3.utils.BN;
const STAKE_CLASS = 'StakeWallet';
const UNSTAKE_CLASS = 'Unstake';
const WITHDRAW_CLASS = 'WithdrawStake';
const TOKEN_CLASS = 'TokenDetails';

const GridCard = styled(Card)(({ theme }) => ({
  padding: '20px',
  display: 'flex',
  height: '100%',
  // justifyContent: 'center',
  // alignItems: 'center',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius
}));

const Tab = styled(TabUnstyled)(({ theme }) => ({
  color: '#999999',
  cursor: 'pointer',
  fontSize: '2rem',
  fontFamily: 'Poppins',
  fontWeight: 300,
  width: '100%',
  display: 'flex',
  backgroundColor: 'transparent',
  padding: '12px 16px 12px',
  margin: '0px 8px',
  border: 'none',
  borderBottom: `solid 2px transparent`,
  alignItems: 'center ',
  justifyContent: 'center',

  '&:hover': {
    borderBottom: `solid 2px ${theme.palette.primary.dark}`,
    color: '#222222'
  },
  '&:hover img': {
    filter: 'grayscale(0%) !important'
  }

  //   [`&.${tabUnstyledClasses.selected}`]: {
  //     borderBottom: `solid 2px ${theme.palette.primary.main}`,
  //     fontWeight: 500,
  //     color: '#222222'
  //   },

  //   [`&.${tabUnstyledClasses.selected} img`]: {
  //     filter: 'grayscale(0%) !important'
  //   },

  //   [`&.${buttonUnstyledClasses.disabled}`]: {
  //     opacity: '0.5',
  //     cursor: 'not-allowed'
  //   }
}));

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
`;

const TabsList = styled(TabsListUnstyled)(({ theme }) => ({
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignContent: 'space-between'
}));

// ---------------------------------------------

export default function MainStaking() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch();
  const {
    isLoading: isLoadingUser,
    account,
    stakingWallets: stakingWalletsR,
    totalStaked,
    unstakeWallet: unstakeWalletR,
    withdrawHistory: withdrawHistoryR
  } = useSelector((state) => state.user);
  const {
    isLoading,
    icoSettings,
    tokenStaking,
    tokenDetails: tokenDetailsR,
    erc20
  } = useSelector((state) => state.ico);
  const tokenStakingAbi = JSON.parse(JSON.stringify(tokenStaking.abi));
  const erc20Abi = JSON.parse(JSON.stringify(erc20.abi));
  const [tokenStakingContract, setTokenStakingContract] = useState(null);
  const { isAuthenticated, enableWeb3, isWeb3Enabled, web3: web3m, Moralis } = useMoralis();
  const Web3Api = useMoralisWeb3Api();
  const { switchNetwork, chainId, chain } = useChain();
  const [tokenDetails, setTokenDetails] = useState(0);
  const [tokenBalances, setTokenBalances] = useState([]);
  const [interestToken, setInterestToken] = useState();
  const [stakedAmount, setStakedAmount] = useState([]);
  const [stakingWallets, setStakingWallets] = useState([]);
  const [unstakeWallet, setUnstakeWallet] = useState();
  const [withdrawHistory, setWithdrawHistory] = useState([]);
  const [totalInterest, setTotalInterest] = useState(0);
  const [isLoadingStake, setIsLoadingStake] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openSwitchBsc, setOpenSwitchBsc] = useState(false);
  const [cooldownPeriod, setCooldownPeriod] = useState(dayjs.duration('604800', 'seconds').humanize());
  const currentUnixDate = dayjs().unix();

  const web3 = new Web3(Moralis.provider);

  // const { fetchERC20Balances } = useERC20Balances();

  // const {
  //   data: dataTokenBalances,
  //   error: errorTokenBalances,
  //   isLoading: isLoadingTokenBalances,
  //   fetch: fetchTokenBalances
  // } = useMoralisQuery('BscTokenBalance', (query) => query.equalTo('address', account.toLowerCase()), [account], {
  //   live: true
  // });

  // commented out on 2023-06-08 because we can just refresh the page to fetch updated balances, otherwise wed need to run another moralis stream
  // useMoralisSubscription('BscTokenBalance', (query) => query.equalTo('address', account.toLowerCase()), [account], {
  //   onCreate: (_) => {
  //     fetchTokenBalancesMoralis();
  //   },
  //   onUpdate: (_) => {
  //     fetchTokenBalancesMoralis();
  //   }
  // });

  const {
    data: dataStaking,
    error: errorStaking,
    isLoading: isLoadingStaking,
    fetch: fetchStaking
  } = useMoralisQuery(
    STAKE_CLASS,
    (query) =>
      query
        .equalTo('owner', account?.toLowerCase())
        .equalTo('chainId', parseInt(chainId, 16))
        .descending('block_timestamp'),
    [account, chainId],
    {
      live: true
    }
  );

  useMoralisSubscription(
    STAKE_CLASS,
    (query) =>
      query
        .equalTo('owner', account?.toLowerCase())
        .equalTo('chainId', parseInt(chainId, 16))
        .descending('block_timestamp'),
    [account, chainId],
    {
      onCreate: (data) => {
        console.log('stake success');
        setIsLoadingStake(true);
        fetchStaking();
        // fetchTokenBalances();
        fetchTokenBalancesMoralis();
        setSuccessMessage(
          <>
            Successfully Staked{' '}
            <b>
              {Number(web3.utils.fromWei(data.attributes.amount)).toLocaleString('en-US', { maximumFractionDigits: 2 })}{' '}
              {getTokenSymbol(data.attributes.tokenAddress)} tokens
            </b>
          </>
        );
        setOpenSuccess(true);
      },
      onUpdate: (_) => {
        fetchStaking();
        // fetchTokenBalances();
        fetchTokenBalancesMoralis();
        setIsLoadingStake(false);
      }
    }
  );

  const {
    data: dataUnstake,
    error: errorUnstake,
    isLoading: isLoadingUnstake,
    fetch: fetchUnstake
  } = useMoralisQuery(
    UNSTAKE_CLASS,
    (query) =>
      query
        .equalTo('owner', account?.toLowerCase())
        .equalTo('chainId', parseInt(chainId, 16))
        .descending('block_timestamp'),
    [account, chainId],
    {
      live: true
    }
  );

  useMoralisSubscription(
    UNSTAKE_CLASS,
    (query) =>
      query
        .equalTo('owner', account?.toLowerCase())
        .equalTo('chainId', parseInt(chainId, 16))
        .descending('block_timestamp'),
    [account, chainId],
    {
      onCreate: (data) => {
        if (data.attributes.amount !== '0') {
          console.log('unstake success');
          setIsLoadingStake(true);
          fetchStaking();
          fetchUnstake();
          fetchWithdrawStake();
          // fetchTokenBalances();
          fetchTokenBalancesMoralis();
          setSuccessMessage(
            <>
              Successfully Unstaked{' '}
              <b>
                {Number(web3.utils.fromWei(data.attributes.amount)).toLocaleString('en-US', {
                  maximumFractionDigits: 2
                })}{' '}
                {getTokenSymbol(data.attributes.tokenAddress)} tokens + interest of{' '}
                {Number(web3.utils.fromWei(data.attributes.interest)).toLocaleString('en-US', {
                  maximumFractionDigits: 2
                })}{' '}
                {getTokenSymbol(data.attributes.interestTokenAddress)} tokens
              </b>
              . You will be able to withdraw your tokens on {dayjs.unix(data.attributes.canWithdrawDate).toString()}.
            </>
          );
          setOpenSuccess(true);
        }
      },
      onUpdate: (_) => {
        fetchStaking();
        fetchUnstake();
        fetchWithdrawStake();
        // fetchTokenBalances();
        fetchTokenBalancesMoralis();
        setIsLoadingStake(false);
      }
    }
  );

  const {
    data: dataWithdrawStake,
    error: errorWithdrawStake,
    isLoading: isLoadingWithdrawStake,
    fetch: fetchWithdrawStake
  } = useMoralisQuery(
    WITHDRAW_CLASS,
    (query) =>
      query
        .equalTo('owner', account?.toLowerCase())
        .equalTo('chainId', parseInt(chainId, 16))
        .descending('block_timestamp'),
    [account, chainId],
    {
      live: true
    }
  );

  useMoralisSubscription(
    WITHDRAW_CLASS,
    (query) =>
      query
        .equalTo('owner', account?.toLowerCase())
        .equalTo('chainId', parseInt(chainId, 16))
        .descending('block_timestamp'),
    [account, chainId],
    {
      onCreate: (data) => {
        console.log('withdraw success');
        setIsLoadingStake(true);
        fetchUnstake();
        fetchWithdrawStake();
        // fetchTokenBalances();
        fetchTokenBalancesMoralis();
        setSuccessMessage(
          <>
            Successfully Withdrew{' '}
            <b>
              {Number(web3.utils.fromWei(data.attributes.amount)).toLocaleString('en-US', { maximumFractionDigits: 2 })}{' '}
              {getTokenSymbol(data.attributes.tokenAddress)} tokens + interest of{' '}
              {Number(web3.utils.fromWei(data.attributes.interest)).toLocaleString('en-US', {
                maximumFractionDigits: 2
              })}{' '}
              {getTokenSymbol(data.attributes.interestTokenAddress)} tokens.
            </b>
          </>
        );
        setOpenSuccess(true);
      },
      onUpdate: (_) => {
        fetchUnstake();
        fetchWithdrawStake();
        // fetchTokenBalances();
        fetchTokenBalancesMoralis();
        setIsLoadingStake(false);
      }
    }
  );

  const {
    data: dataTokens,
    error: errorTokens,
    isLoading: isLoadingTokens,
    fetch: fetchTokens
  } = useMoralisQuery(
    TOKEN_CLASS,
    (query) => query.equalTo('chainId', parseInt(chainId, 16)).descending('block_timestamp'),
    [chainId],
    { live: true }
  );

  useMoralisSubscription(
    TOKEN_CLASS,
    (query) => query.equalTo('chainId', parseInt(chainId, 16)).descending('block_timestamp'),
    [chainId],
    {
      onUpdate: (_) => {
        fetchTokens();
      }
    }
  );

  useEffect(() => {
    if (!isWeb3Enabled) enableWeb3();
  }, [isWeb3Enabled]);

  const getTokenSymbol = (address) => {
    if (icoSettings && icoSettings.stakingCryptoArray.length > 0) {
      const { symbol } = icoSettings.stakingCryptoArray.find(
        (e) => e.address?.toLowerCase() === address?.toLowerCase()
      );
      return symbol;
    }
  };

  const setContract = async () => {
    try {
      // const netId = await web3.eth.net.getId();
      const netId = String(chain.networkId);
      console.log('tokenStaking.networks[netId]', tokenStaking.networks[netId]);
      console.log('tokenStakingAbi', tokenStakingAbi);
      const tokenStakingAddress = tokenStaking.networks[netId]
        ? tokenStaking.networks[netId].address?.toLowerCase()
        : undefined;
      if (tokenStakingAddress) setTokenStakingContract(new web3.eth.Contract(tokenStakingAbi, tokenStakingAddress));
    } catch (e) {
      console.log(e);
    }
  };

  const handleStake = async (crypto, stakeAmountUnformatted, tokenAddress) => {
    const stakeAmount = web3.utils.toWei(stakeAmountUnformatted);
    setIsLoadingStake(true);
    try {
      if (tokenStakingContract) {
        const netId = String(chain.networkId);
        const tokenStakingAddress = tokenStaking.networks[netId]
          ? tokenStaking.networks[netId].address?.toLowerCase()
          : undefined;
        const allowance = await fetchTokenAllowance(tokenAddress);
        //
        if (new BN(allowance).lte(new BN(stakeAmount))) {
          const erc20Contract = new web3.eth.Contract(erc20Abi, tokenAddress);
          const totalErc20 = await erc20Contract.methods.totalSupply().call({ from: account });
          await erc20Contract.methods.approve(tokenStakingAddress, totalErc20).send({ from: account });
          await new Promise((resolve) => setTimeout(resolve, 10000));
        }
        //
        await tokenStakingContract.methods
          .depositStake(web3.utils.asciiToHex(crypto), stakeAmount)
          .send({ from: account });
      } else {
        throw new Error('Contract not available.');
      }
    } catch (error) {
      console.log('depositStake error : ', error);
    } finally {
      setIsLoadingStake(false);
    }
  };

  const handleUnstake = async (crypto, unstakeAmount) => {
    setIsLoadingStake(true);
    try {
      if (tokenStakingContract) {
        await tokenStakingContract.methods
          .initiateUnstake(web3.utils.asciiToHex(crypto), web3.utils.toWei(unstakeAmount))
          .send({ from: account });
      } else {
        throw new Error('Contract not available.');
      }
    } catch (error) {
      console.log('initiateUnstake error : ', error);
    } finally {
      setIsLoadingStake(false);
    }
  };

  const handleWithdraw = async (crypto) => {
    setIsLoadingStake(true);
    try {
      if (tokenStakingContract) {
        await tokenStakingContract.methods.withdrawStake(web3.utils.asciiToHex(crypto)).send({ from: account });
      } else {
        throw new Error('Contract not available.');
      }
    } catch (error) {
      console.log('withdrawStake error : ', error);
    } finally {
      setIsLoadingStake(false);
    }
  };

  const fetchTokenBalancesMoralis = async () => {
    const options = {
      chain: chainId,
      address: account
    };
    const balances = await Web3Api.account.getTokenBalances(options);
    console.log('balances', balances);
    // const balances = await Moralis.EvmApi.token.getWalletTokenBalances(options);
    // const balances = await fetchERC20Balances({ params: options });
    setTokenBalances(balances);
  };

  const fetchTokenAllowance = async (address) => {
    const netId = String(chain.networkId);
    const tokenStakingAddress = tokenStaking.networks[netId]
      ? tokenStaking.networks[netId].address?.toLowerCase()
      : undefined;
    // Get token allowace on ETH
    const options = {
      chain: chainId,
      // token holder
      owner_address: account,
      // uniswap v3 router 2 contract address
      spender_address: tokenStakingAddress,
      // ENS token contract address
      address
    };
    const { allowance } = await Web3Api.token.getTokenAllowance(options);
    return allowance;
  };

  const getInterestToken = async () => {
    let token = await tokenStakingContract.methods.interestToken().call({ from: account });
    token = String(web3.utils.hexToAscii(token));
    setInterestToken(token);
  };

  useEffect(() => {
    (async () => {
      if (tokenStakingContract) {
        const cd = await tokenStakingContract.methods.unstakeCooldown().call({ from: account });
        setCooldownPeriod(dayjs.duration(cd, 'seconds').humanize());
      }
    })();
  }, [tokenStakingContract]);

  useEffect(() => {
    if (tokenStaking && isWeb3Enabled) setContract();
  }, [isWeb3Enabled, tokenStaking, chainId]);

  useEffect(() => {
    if (icoSettings && dataTokens && dataTokens.length > 0) {
      dispatch(setTokenDetailsR(dataTokens, icoSettings.stakingCryptoArray));
    } else {
      dispatch(setTokenDetailsR([]));
    }
  }, [icoSettings, dataTokens, dispatch]);

  console.log('tokenDetailsR', tokenDetailsR);

  useEffect(() => {
    if (icoSettings && dataStaking && dataStaking.length > 0) {
      dispatch(setStakingWalletsR(dataStaking, icoSettings.stakingCryptoArray));
    } else {
      dispatch(setStakingWalletsR([]));
    }
  }, [icoSettings, dataStaking, dispatch]);

  useEffect(() => {
    if (icoSettings && dataUnstake && dataUnstake.length > 0) {
      dispatch(setUnstakeWalletR(dataUnstake, icoSettings.stakingCryptoArray));
    } else {
      dispatch(setUnstakeWalletR([]));
    }
  }, [icoSettings, dataUnstake, dispatch]);

  useEffect(() => {
    if (icoSettings && dataWithdrawStake && dataWithdrawStake.length > 0) {
      dispatch(setWithdrawHistoryR(dataWithdrawStake, icoSettings.stakingCryptoArray));
    } else {
      dispatch(setWithdrawHistoryR([]));
    }
  }, [icoSettings, dataWithdrawStake, dispatch]);

  useEffect(() => {
    if (account && chainId) fetchTokenBalancesMoralis();
  }, [account, chainId]);

  // useEffect(() => {
  //   setTokenBalances(dataTokenBalances);
  // }, [dataTokenBalances]);

  useEffect(() => {
    setTokenDetails(tokenDetailsR);
  }, [tokenDetailsR]);

  useEffect(() => {
    setStakingWallets(stakingWalletsR);
  }, [stakingWalletsR]);

  useEffect(() => {
    setStakedAmount(totalStaked);
  }, [totalStaked]);

  useEffect(() => {
    setUnstakeWallet(unstakeWalletR);
  }, [unstakeWalletR]);

  useEffect(() => {
    setWithdrawHistory(withdrawHistoryR);
  }, [withdrawHistoryR]);

  useEffect(() => {
    if (tokenStakingContract) getInterestToken();
  }, [tokenStakingContract]);

  useEffect(() => {
    if (icoSettings && chainId !== icoSettings.networkKey && isWeb3Enabled) handleOpenBscAlert();
  }, [chainId, icoSettings, isWeb3Enabled]);

  const handleOpenBscAlert = () => {
    setOpenSwitchBsc(true);
  };

  const handleCloseBscAlert = () => {
    setOpenSwitchBsc(false);
  };

  return (
    <>
      <AlertMessage open={openSuccess} setOpen={setOpenSuccess} message={successMessage} severity="success" />

      <Stack spacing={4} p={2} sx={{ width: '100%' }} alignItems="center">
        <Box sx={{ height: { xs: 75, sm: 100 } }}>
          <img alt="YOUWHO logo" src="/static/logos/svg/youwho-teal_text-long-alt.svg" width={570} height={100} />
        </Box>

        <Typography variant="h2" fontWeight="300">
          Staking Portal Open
        </Typography>

        <LoginModal buttonText="Login / Connect" />

        {icoSettings && chainId !== icoSettings.networkKey && isWeb3Enabled && (
          <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={openSwitchBsc} key="bscSwitch">
            <Alert
              severity="warning"
              onClose={handleCloseBscAlert}
              action={
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={() => switchNetwork(icoSettings.networkKey)}
                  startIcon={<Icon icon="simple-icons:binance" width={20} height={20} />}
                  sx={{ color: 'white' }}
                >
                  Switch
                </Button>
              }
            >
              Switch to BSC
            </Alert>
          </Snackbar>
        )}

        {icoSettings &&
          chainId !== icoSettings.networkKey &&
          window.ethereum &&
          isAuthenticated && ( // chainId !== icoSettings.networkKey
            <Button
              size="large"
              variant="outlined"
              color="secondary"
              onClick={() => switchNetwork(icoSettings.networkKey)}
              startIcon={<Icon icon="simple-icons:binance" width={20} height={20} />}
              sx={{ width: { xs: '100%', sm: '400px' }, textTransform: 'none' }}
            >
              Switch to BSC
            </Button>
          )}
        {isLoading || isLoadingUser || isLoadingStaking ? (
          <Stack spacing={0} alignItems="center" justifyContent="center" height="300px">
            <LoadingScreen />
          </Stack>
        ) : (
          <>
            {icoSettings && isAuthenticated && tokenStakingContract && (
              <StakingTabs
                isAuthenticated={isAuthenticated}
                smUp={smUp}
                tokenDetails={tokenDetails}
                theme={theme}
                cd={cooldownPeriod}
                tokenBalances={tokenBalances}
                handleStake={handleStake}
                handleUnstake={handleUnstake}
                handleWithdraw={handleWithdraw}
                icoSettings={icoSettings}
                stakingWallets={stakingWallets}
                getTokenSymbol={getTokenSymbol}
                stakedAmount={stakedAmount}
                unstakeWallet={unstakeWallet}
                withdrawHistory={withdrawHistory}
                currentUnixDate={currentUnixDate}
                interestToken={interestToken}
                setTotalInterest={setTotalInterest}
                totalInterest={totalInterest}
                isLoadingStake={isLoadingStake}
                web3={web3}
              />
            )}
          </>
        )}
      </Stack>
    </>
  );
}

// ---------------------------------------------

function StakingTabs({
  isAuthenticated,
  smUp,
  tokenDetails,
  theme,
  cd,
  tokenBalances,
  handleStake,
  handleUnstake,
  handleWithdraw,
  icoSettings,
  stakingWallets,
  getTokenSymbol,
  stakedAmount,
  unstakeWallet,
  withdrawHistory,
  currentUnixDate,
  interestToken,
  setTotalInterest,
  totalInterest,
  isLoadingStake,
  web3
}) {
  return (
    <TabsUnstyled style={{ width: '100%' }} defaultValue={0}>
      <TabsList sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
        {tokenDetails.map((x, i) => (
          // <Tab key={i}>
          <Tab key={i} disabled={i === icoSettings.stakingTabsToShow}>
            <img
              alt={`${i === 0 ? '$YOU' : '$YOULP'}`}
              src={`/static/logos/svg/youwho-logos_token-${i === 0 ? 'alt' : 'LP'}.svg`}
              height={smUp ? '43px' : '30px'}
              style={{ margin: '0px 10px', filter: 'grayscale(100%)' }}
            />
            Staking
          </Tab>
        ))}
      </TabsList>

      {tokenDetails.map((x, i) => (
        <TabPanel value={i} key={i}>
          <Stack spacing={2} justifyContent="center" sx={{ marginTop: '40px' }}>
            <Stack
              flexDirection={smUp ? 'column' : 'column'}
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >
              <Typography variant="h1" fontWeight="600">
                {Number(stakedAmount[i] ? web3.utils.fromWei(stakedAmount[i]) : 0).toLocaleString('en-US', {
                  maximumFractionDigits: 2
                })}
              </Typography>
              <Stack flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="center">
                <img
                  alt="$YOU"
                  src={`/static/logos/svg/youwho-logos_token-${i === 0 ? 'alt' : 'LP'}.svg`}
                  height={smUp ? '50px' : '35px'}
                  style={{ margin: '0px 8px' }}
                />
                <Typography variant="h2" fontWeight="300">
                  Staked
                </Typography>
              </Stack>
            </Stack>

            <Stack flexDirection={smUp ? 'row' : 'column'}>
              <Typography variant="h4">Token Paid Out As Interest :&nbsp;</Typography>
              <Typography variant="h4" fontWeight="300">
                {interestToken} Token
              </Typography>
            </Stack>

            {totalInterest > 0 && (
              <Stack flexDirection={smUp ? 'row' : 'column'}>
                <Typography variant="h4">Interest Earned :&nbsp;</Typography>
                <Typography variant="h4" fontWeight="300">
                  {totalInterest.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                  &nbsp;
                  {interestToken}
                </Typography>
              </Stack>
            )}

            <Stack flexDirection={smUp ? 'row' : 'column'}>
              <Typography variant="h4">Latest APY :&nbsp;</Typography>
              <Typography variant="h4" fontWeight="300">
                {x.rateConverter ? (
                  <>
                    {(x.stakingApy / 100 / x.rateConverter).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                    %
                  </>
                ) : (
                  <>
                    {(x.stakingApy / 100).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                    %
                  </>
                )}
              </Typography>
              {/* <Typography variant="h4" fontWeight="300">
                {(x.stakingApy / 100).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
                %{' '}
                {x.rateConverter && (
                  <>
                    (
                    {(x.stakingApy / 100 / x.rateConverter).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                    % APY approx. on total liquidity provided)
                  </>
                )}
              </Typography> */}
            </Stack>

            {x.stakingEndDate !== '0' && (
              <Stack flexDirection={smUp ? 'column' : 'column'}>
                <Typography variant="h4">Staking Pool End Date :&nbsp;</Typography>
                <Typography variant="h4" fontWeight="300">
                  {dayjs.unix(x.stakingEndDate).toString()}
                </Typography>
              </Stack>
            )}

            <Stack flexDirection={smUp ? 'row' : 'column'}>
              <Typography variant="h4">Token Address :&nbsp;</Typography>
              <Typography variant="h4" fontWeight="300">
                {isAuthenticated ? getEllipsisTxt(x.tokenAddress, 10) : '...'}
                <Link href={`https://bscscan.com/token/${x.tokenAddress}`} target="blank">
                  <IconButton sx={{ mb: '4px' }} size="small">
                    <Icon icon="heroicons-outline:external-link" color={theme.palette.primary.main} />
                  </IconButton>
                </Link>
              </Typography>
            </Stack>

            <Stack flexDirection={smUp ? 'row' : 'column'}>
              <Typography variant="h4">Unstake Cooldown :&nbsp;</Typography>
              <Typography variant="h4" fontWeight="300">
                {cd}
              </Typography>
            </Stack>

            <Divider light variant="middle" sx={{ width: '80%', py: '10px', alignSelf: 'center' }} />
            <StakingDashboard
              smUp={smUp}
              tokenBalances={tokenBalances}
              tokenAddress={x.tokenAddress}
              handleStake={handleStake}
              handleUnstake={handleUnstake}
              handleWithdraw={handleWithdraw}
              crypto={icoSettings ? icoSettings.stakingCryptoArray[i] : undefined}
              stakedAmount={stakedAmount[i] ? stakedAmount[i] : new BN(0)}
              stakingWallets={stakingWallets[i]}
              unstakeWallet={unstakeWallet[i]}
              currentUnixDate={currentUnixDate}
              isLoadingStake={isLoadingStake}
              getTokenSymbol={getTokenSymbol}
              web3={web3}
            />
            <Divider light variant="middle" sx={{ width: '80%', py: '10px', alignSelf: 'center' }} />
            <StakingWallets
              smUp={smUp}
              handleUnstake={handleUnstake}
              stakingWallets={stakingWallets[i]}
              getTokenSymbol={getTokenSymbol}
              currentUnixDate={currentUnixDate}
              theme={theme}
              tokenDetails={tokenDetails[i]}
              interestToken={interestToken}
              setTotalInterest={setTotalInterest}
              tokenAddress={x.tokenAddress}
              web3={web3}
            />
            <Divider light variant="middle" sx={{ width: '80%', py: '10px', alignSelf: 'center' }} />
            <WithdrawHistory
              tokenAddress={x.tokenAddress}
              smUp={smUp}
              withdrawHistory={withdrawHistory[i]}
              getTokenSymbol={getTokenSymbol}
              theme={theme}
              web3={web3}
            />
          </Stack>
        </TabPanel>
      ))}
    </TabsUnstyled>
  );
}

function StakingDashboard({
  smUp,
  tokenBalances,
  tokenAddress,
  handleStake,
  handleUnstake,
  handleWithdraw,
  crypto,
  stakedAmount,
  stakingWallets,
  unstakeWallet,
  currentUnixDate,
  isLoadingStake,
  getTokenSymbol,
  web3
}) {
  const [stakeAmount, setStakeAmount] = useState('0');
  const [unstakeAmount, setUnstakeAmount] = useState('0');
  const [isOnCooldown, setIsOnCooldown] = useState(false);
  const [tokenObject, setTokenObject] = useState();

  useEffect(() => {
    if (unstakeWallet && unstakeWallet.canWithdrawDate > currentUnixDate) setIsOnCooldown(true);
  }, [unstakeWallet]);

  useEffect(() => {
    if (tokenBalances) {
      // const tokenObj = tokenBalances.find((e) => e.attributes.token_address === tokenAddress);
      const tokenObj = tokenBalances.find((e) => e.token_address === tokenAddress);
      setTokenObject(tokenObj);
    }
  }, [tokenBalances]);

  const stakeValidationSchema = Yup.object().shape({
    amount: Yup.number()
      .moreThan(0)
      .test(
        'test if stake amount is less than balance',
        `Amount must be less than ${tokenObject ? web3.utils.fromWei(tokenObject ? tokenObject.balance : '0') : '0'}`,
        () =>
          new BN(web3.utils.toWei(stakeAmount)).lte(
            tokenObject ? new BN(tokenObject ? tokenObject.balance : '0') : new BN(0)
          )
      )
      .required()
  });

  const StakeFormik = useFormik({
    initialValues: {
      amount: '0'
    },
    validationSchema: stakeValidationSchema,
    onSubmit: async ({ _ }, { resetForm }) => {
      try {
        await handleStake(crypto.symbol, stakeAmount, crypto.address);
        resetForm();
        setStakeAmount('0');
      } catch (error) {
        console.log('StakeFormik error : ', error);
      }
    }
  });

  useEffect(() => {
    StakeFormik.setFieldValue('amount', stakeAmount);
  }, [stakeAmount]);

  // console.log('stakedAmount', stakedAmount);

  const unstakeValidationSchema = Yup.object().shape({
    amount: Yup.number()
      .moreThan(0)
      .test(
        'test if unstake amount is less than balance',
        `Amount must be less than ${web3.utils.fromWei(stakedAmount)}`,
        () => new BN(web3.utils.toWei(unstakeAmount)).lte(stakedAmount)
      )
      .required()
  });

  const UnstakeFormik = useFormik({
    initialValues: {
      amount: '0'
    },
    validationSchema: unstakeValidationSchema,
    onSubmit: async ({ amount }, { resetForm }) => {
      try {
        await handleUnstake(crypto.symbol, unstakeAmount);
        resetForm();
        setUnstakeAmount('0');
      } catch (error) {
        console.log('UnstakeFormik error : ', error);
      }
    }
  });

  useEffect(() => {
    UnstakeFormik.setFieldValue('amount', unstakeAmount);
  }, [unstakeAmount]);

  const renderer = ({ completed, formatted: { days, hours, minutes, seconds } }) => (
    // Render a countdown
    <Grid container spacing={2}>
      {[
        { time: days, word: 'days' },
        { time: hours, word: 'hours' },
        { time: minutes, word: 'mins' },
        { time: seconds, word: 'sec' }
      ].map((obj, i) => (
        <Grid item xs={6} md={3} key={i}>
          <GridCard
            sx={{
              background: '#f4f4f4',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography variant="h2" fontWeight="500" color="primary.main" lineHeight="1">
              {obj.time}
            </Typography>
            <Typography variant="h5" fontWeight="300">
              {obj.word}
            </Typography>
          </GridCard>
        </Grid>
      ))}
    </Grid>
  );
  return (
    <Stack sx={{ width: '100%' }}>
      <Stack flexDirection={smUp ? 'row' : 'column'} alignItems={smUp ? 'center' : 'flex-start'}>
        <Typography variant="h3">Staking Dashboard</Typography>
      </Stack>
      {tokenObject ||
      (!tokenObject && unstakeWallet && unstakeWallet.amount !== '0') ||
      (stakingWallets && stakingWallets.length > 0) ? (
        <Grid container sx={{ paddingTop: '20px' }}>
          <Grid item xs={12} sm={6} sx={{ p: '10px' }}>
            <GridCard sx={(theme) => ({ backgroundColor: alpha(theme.palette.primary.main, 0.2) })}>
              <Typography variant="h5">Stake</Typography>
              <Box sx={{ alignSelf: 'flex-end', marginBottom: '10px' }}>
                {stakingWallets && stakingWallets.length > 0 && stakingWallets[0].confirmed === false ? (
                  <CircularProgress size="20px" />
                ) : (
                  <Button
                    variant="text"
                    onClick={() => setStakeAmount(web3.utils.fromWei(tokenObject ? tokenObject.balance : '0'))}
                  >
                    <Typography color="#222222">max.</Typography>&nbsp;
                    <Typography fontWeight="500" color="#1e5844">
                      {Number(web3.utils.fromWei(tokenObject ? tokenObject.balance : '0')).toLocaleString('en-US', {
                        maximumFractionDigits: 2
                      })}
                      &nbsp;
                      {crypto.symbol}
                    </Typography>
                  </Button>
                )}
              </Box>
              <FormikProvider value={StakeFormik}>
                <Form onSubmit={StakeFormik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        {...StakeFormik.getFieldProps('amount')}
                        label="enter amount to stake"
                        fullWidth
                        type="number"
                        onFocus={(event) => {
                          event.target.select();
                        }}
                        value={stakeAmount}
                        onChange={(e) => {
                          setStakeAmount(e.target.value ? e.target.value : '0');
                        }}
                        sx={{ input: { fontWeight: '600', fontSize: '20px' } }}
                        error={Boolean(StakeFormik.touched.amount && StakeFormik.errors.amount)}
                        helperText={(StakeFormik.touched.amount && StakeFormik.errors.amount) || ' '}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <b>{crypto.symbol}</b>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LoadingButton
                        type="submit"
                        fullWidth
                        color="primary"
                        variant="contained"
                        size="large"
                        sx={{ height: 56, fontSize: { xs: '1rem', sm: '1.2rem' }, color: 'white' }}
                        loading={isLoadingStake}
                      >
                        Stake
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </GridCard>
          </Grid>
          {((unstakeWallet && unstakeWallet.amount !== '0') || !stakedAmount.isZero()) && (
            <Grid item xs={12} sm={6} sx={{ p: '10px' }}>
              <GridCard
                sx={(theme) => ({
                  backgroundColor: alpha(theme.palette.secondary.main, 0.2),
                  justifyContent: 'space-between'
                })}
              >
                {((!unstakeWallet && !stakedAmount.isZero()) ||
                  (unstakeWallet && unstakeWallet.amount === '0' && !stakedAmount.isZero())) && (
                  <>
                    <Typography variant="h5">Unstake</Typography>
                    <Box sx={{ alignSelf: 'flex-end', marginBottom: '10px' }}>
                      {stakingWallets && stakingWallets.length > 0 && stakingWallets[0].confirmed === false ? (
                        <CircularProgress color="secondary" size="20px" />
                      ) : (
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={() => setUnstakeAmount(web3.utils.fromWei(stakedAmount))}
                        >
                          <Typography color="#222222">max.</Typography>&nbsp;
                          <Typography
                            fontWeight="500"
                            sx={(theme) => ({
                              color: theme.palette.secondary.dark
                            })}
                          >
                            {Number(web3.utils.fromWei(stakedAmount)).toLocaleString('en-US', {
                              maximumFractionDigits: 2
                            })}
                            &nbsp;
                            {crypto.symbol}
                          </Typography>
                        </Button>
                      )}
                    </Box>
                    <FormikProvider value={UnstakeFormik}>
                      <Form onSubmit={UnstakeFormik.handleSubmit}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              {...UnstakeFormik.getFieldProps('amount')}
                              label="enter amount to unstake"
                              fullWidth
                              type="number"
                              onFocus={(event) => {
                                event.target.select();
                              }}
                              value={unstakeAmount}
                              onChange={(e) => {
                                setUnstakeAmount(e.target.value ? e.target.value : '0');
                              }}
                              sx={{ input: { fontWeight: '600', fontSize: '20px' } }}
                              error={Boolean(UnstakeFormik.touched.amount && UnstakeFormik.errors.amount)}
                              helperText={(UnstakeFormik.touched.amount && UnstakeFormik.errors.amount) || ' '}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <b>{crypto.symbol}</b>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <LoadingButton
                              type="submit"
                              fullWidth
                              color="secondary"
                              variant="contained"
                              size="large"
                              sx={{ height: 56, fontSize: { xs: '1rem', sm: '1.2rem' }, color: 'white' }}
                              loading={isLoadingStake}
                            >
                              Unstake
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Form>
                    </FormikProvider>
                  </>
                )}

                {unstakeWallet && unstakeWallet.amount !== '0' && (
                  <>
                    {isOnCooldown ? (
                      <>
                        <Typography variant="h5">Withdraw</Typography>
                        <Typography variant="h5" fontWeight="300" sx={{ my: '10px' }}>
                          You will be able to withdraw{' '}
                          <b>
                            {Number(web3.utils.fromWei(unstakeWallet.amount)).toLocaleString('en-US', {
                              maximumFractionDigits: 2
                            })}
                            &nbsp;
                            {crypto.symbol}
                          </b>{' '}
                          and{' '}
                          <b>
                            {Number(web3.utils.fromWei(unstakeWallet.interest)).toLocaleString('en-US', {
                              maximumFractionDigits: 2
                            })}
                            &nbsp;
                            {getTokenSymbol(unstakeWallet.interestTokenAddress)}
                          </b>{' '}
                          tokens in :
                        </Typography>
                        <Countdown
                          date={unstakeWallet.canWithdrawDate * 1000}
                          renderer={renderer}
                          onComplete={() => setIsOnCooldown(false)}
                        />
                      </>
                    ) : (
                      <>
                        <Typography variant="h5">Withdraw</Typography>
                        <Typography variant="h5" fontWeight="300" sx={{ my: '10px' }}>
                          Your tokens are ready to be withdrawn :
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12}>
                            <Stack spacing={0} alignItems="center" justifyContent="center">
                              <Typography variant="h3">
                                {Number(web3.utils.fromWei(unstakeWallet.amount)).toLocaleString('en-US', {
                                  maximumFractionDigits: 2
                                })}
                                &nbsp;
                                {crypto.symbol}
                              </Typography>
                              <Typography variant="h3" fontWeight="300">
                                &nbsp;+&nbsp;
                              </Typography>
                              <Typography variant="h3">
                                {Number(web3.utils.fromWei(unstakeWallet.interest)).toLocaleString('en-US', {
                                  maximumFractionDigits: 2
                                })}
                                &nbsp;
                                {getTokenSymbol(unstakeWallet.interestTokenAddress)}{' '}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <LoadingButton
                              color="info"
                              variant="contained"
                              size="large"
                              loading={isLoadingStake}
                              sx={{ height: 56, fontSize: { xs: '1rem', sm: '1.2rem' }, color: 'white', width: '100%' }}
                              onClick={() => handleWithdraw(crypto.symbol)}
                            >
                              Withdraw
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </GridCard>
            </Grid>
          )}
        </Grid>
      ) : (
        <Stack>
          <Typography variant="h4" fontWeight="300">
            no data for this address, please try a different address.
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

function StakingWallets({
  smUp,
  stakingWallets,
  getTokenSymbol,
  theme,
  currentUnixDate,
  tokenDetails,
  interestToken,
  setTotalInterest,
  tokenAddress,
  web3
}) {
  const columns = [
    { field: 'id', align: 'center', headerAlign: 'center', headerName: '#', width: 50 },
    {
      field: 'date',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerName: 'date staked',
      minWidth: 100,
      renderCell: (params) => {
        const getDate = params.row.date;
        return (
          <Tooltip title={getDate.date} arrow placement="top">
            <b>{getDate.age}</b>
          </Tooltip>
        );
      }
    },
    {
      field: 'stake',
      headerName: 'stake',
      flex: 1,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const getInvested = params.row.staked;
        return <b>{getInvested}</b>;
      }
    },
    {
      field: 'apy',
      headerName: 'apy',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false
    },
    {
      field: 'interest',
      headerName: 'interest',
      flex: 1,
      minWidth: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const getYield = params.row.interest;
        return <b>{getYield}</b>;
      }
    },
    {
      field: 'tx',
      headerName: 'tx',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const getLink = params.row.tx;
        return (
          <Tooltip title={getLink} arrow placement="top">
            <Link href={`https://bscscan.com/tx/${getLink}`} target="blank">
              {getEllipsisTxt(getLink, 5)}
            </Link>
          </Tooltip>
        );
      }
    },
    {
      field: 'txC',
      headerName: 'cnfm',
      type: 'boolean',
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const txConfirmed = params.row.txC;
        return (
          <Stack alignItems="center" justifyContent="center" sx={{ width: 1, textAlign: 'center' }}>
            {txConfirmed ? (
              <Tooltip title="confirmed" arrow placement="top">
                <Icon icon="eva:checkmark-circle-2-fill" width="20" height="20" color={theme.palette.primary.main} />
              </Tooltip>
            ) : (
              <Tooltip title="unconfirmed" arrow placement="top">
                <Icon icon="eva:close-circle-fill" width="20" height="20" color={theme.palette.secondary.main} />
              </Tooltip>
            )}
          </Stack>
        );
      }
    }
  ];

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (stakingWallets && stakingWallets.length > 0) {
      const rowArr = stakingWallets.map((x, i) => ({
        id: i + 1,
        date: { age: dayjs().to(dayjs.unix(x.createdDate)), date: dayjs.unix(x.createdDate).toString() },
        staked: `${Number(web3.utils.fromWei(x.amount)).toLocaleString('en-US', {
          maximumFractionDigits: 2
        })} ${getTokenSymbol(x.tokenAddress)}`,
        apy: `${(x.stakingApy / 100).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })} %`,
        interest: `${(
          (((x.amount * x.stakingApy) / 10000) *
            ((tokenDetails.stakingEndDate > 0 && tokenDetails.stakingEndDate <= currentUnixDate
              ? tokenDetails.stakingEndDate
              : currentUnixDate) <= x.createdDate
              ? 0
              : (tokenDetails.stakingEndDate > 0 && tokenDetails.stakingEndDate <= currentUnixDate
                  ? tokenDetails.stakingEndDate
                  : currentUnixDate) - x.createdDate)) /
          31557600 /
          10 ** 18
        ).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })} ${interestToken}`,
        interestn:
          (((x.amount * x.stakingApy) / 10000) *
            ((tokenDetails.stakingEndDate > 0 && tokenDetails.stakingEndDate <= currentUnixDate
              ? tokenDetails.stakingEndDate
              : currentUnixDate) <= x.createdDate
              ? 0
              : (tokenDetails.stakingEndDate > 0 && tokenDetails.stakingEndDate <= currentUnixDate
                  ? tokenDetails.stakingEndDate
                  : currentUnixDate) - x.createdDate)) /
          31557600 /
          10 ** 18,
        tx: x.transaction_hash,
        txC: x.confirmed
      }));
      setRows(rowArr);

      if (rowArr.length > 0) {
        let totStaking = 0;
        for (let i = 0; i < rowArr.length; i += 1) {
          totStaking += Number(rowArr[i].interestn);
        }
        setTotalInterest(totStaking);
      }
    } else {
      setTotalInterest(0);
    }
  }, [stakingWallets]);

  return (
    <Stack sx={{ width: '100%' }}>
      <Stack flexDirection={smUp ? 'row' : 'column'} alignItems={smUp ? 'center' : 'flex-start'}>
        <Typography variant="h3">Staking Wallets</Typography>
      </Stack>
      {(!stakingWallets || stakingWallets.length === 0) && (
        <Stack>
          <Typography variant="h4" fontWeight="300">
            no active Staking Wallets for <b>{getTokenSymbol(tokenAddress)}</b> tokens.
          </Typography>
        </Stack>
      )}
      {stakingWallets && stakingWallets.length > 0 && (
        <Stack spacing={2} sx={{ width: '100%', p: '10px', paddingTop: '20px' }}>
          <DataGrid
            autoHeight
            disableSelectionOnClick
            rows={rows}
            columns={columns}
            components={{
              Toolbar: GridToolbar
            }}
          />
        </Stack>
      )}
    </Stack>
  );
}

function WithdrawHistory({ smUp, withdrawHistory, getTokenSymbol, theme, tokenAddress, web3 }) {
  const columns = [
    { field: 'id', align: 'center', headerAlign: 'center', headerName: '#', width: 50 },
    {
      field: 'date',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerName: 'withdrawl date',
      minWidth: 100,
      renderCell: (params) => {
        const getDate = params.row.date;
        return (
          <Tooltip title={getDate.date} arrow placement="top">
            <b>{getDate.age}</b>
          </Tooltip>
        );
      }
    },
    {
      field: 'withdrawn',
      headerName: 'withdrawn',
      flex: 1,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const getWithdrawn = params.row.withdrawn;
        return <b>{getWithdrawn}</b>;
      }
    },
    {
      field: 'interest',
      headerName: 'interest',
      flex: 1,
      minWidth: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const getYield = params.row.interest;
        return <b>{getYield}</b>;
      }
    },
    {
      field: 'tx',
      headerName: 'tx',
      width: 140,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const getLink = params.row.tx;
        return (
          <Tooltip title={getLink} arrow placement="top">
            <Link href={`https://bscscan.com/tx/${getLink}`} target="blank">
              {getEllipsisTxt(getLink, 7)}
            </Link>
          </Tooltip>
        );
      }
    },
    {
      field: 'txC',
      headerName: 'cnfm',
      type: 'boolean',
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const txConfirmed = params.row.txC;
        return (
          <Stack alignItems="center" justifyContent="center" sx={{ width: 1, textAlign: 'center' }}>
            {txConfirmed ? (
              <Tooltip title="confirmed" arrow placement="top">
                <Icon icon="eva:checkmark-circle-2-fill" width="20" height="20" color="#00c999" />
              </Tooltip>
            ) : (
              <Tooltip title="unconfirmed" arrow placement="top">
                <Icon icon="eva:close-circle-fill" width="20" height="20" color="#FFB369" />
              </Tooltip>
            )}
          </Stack>
        );
      }
    }
  ];

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (withdrawHistory && withdrawHistory.length > 0) {
      const rowArr = withdrawHistory.map((x, i) => ({
        id: i + 1,
        date: { age: dayjs().to(dayjs.unix(x.withdrawDate)), date: dayjs.unix(x.withdrawDate).toString() },
        withdrawn: `${Number(web3.utils.fromWei(x.amount)).toLocaleString('en-US', {
          maximumFractionDigits: 2
        })} ${getTokenSymbol(x.tokenAddress)}`,
        interest: `${Number(web3.utils.fromWei(x.interest)).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })} ${getTokenSymbol(x.interestTokenAddress)}`,
        tx: x.transaction_hash,
        txC: x.confirmed
      }));
      setRows(rowArr);
    }
  }, [withdrawHistory]);
  return (
    <Stack sx={{ width: '100%' }}>
      <Stack flexDirection={smUp ? 'row' : 'column'} alignItems={smUp ? 'center' : 'flex-start'}>
        <Typography variant="h3">Withdraw History</Typography>
      </Stack>
      {(!withdrawHistory || withdrawHistory.length === 0) && (
        <Stack>
          <Typography variant="h4" fontWeight="300">
            no Withdrawl History for <b>{getTokenSymbol(tokenAddress)}</b> tokens.
          </Typography>
        </Stack>
      )}
      {withdrawHistory && withdrawHistory.length > 0 && (
        <Stack spacing={2} sx={{ width: '100%', p: '10px', paddingTop: '20px' }}>
          <DataGrid
            autoHeight
            disableSelectionOnClick
            rows={rows}
            columns={columns}
            components={{
              Toolbar: GridToolbar
            }}
          />
        </Stack>
      )}
    </Stack>
  );
}
