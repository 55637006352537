import React from 'react';
import { Snackbar, Alert } from '@mui/material';

export default function AlertMessage({ open, setOpen, message, severity = 'error' }) {
  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <Snackbar
      id="alert-snackbar"
      open={open}
      //   autoHideDuration={20000}
      // onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
