import React, { useState, useEffect } from 'react';
// icons
import { Icon } from '@iconify/react';
// mui
import { Button, Typography, InputAdornment, TextField, Tabs, Tab, Stack, Box, Modal } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMoralis, useNewMoralisObject } from 'react-moralis';
import Moralis from 'moralis-v1';
// formik
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import shorthash from 'shorthash';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { setIcoProvider } from '../../store/slices/ico';
// helpers
import { getEllipsisTxt } from '../../utils/formatters';
// components
import AlertMessage from '../../utils/AlertMessage';

//------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  bgcolor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4
};

//------------------------------------------------

export default function LoginModal({ buttonText }) {
  const { login, logout, signup, authenticate, user, isAuthenticated, setUserData, enableWeb3 } = useMoralis();
  const dispatch = useDispatch();
  const { icoSettings, icoContractAddress } = useSelector((state) => state.ico);
  const { account } = useSelector((state) => state.user);
  const [successMessage, setSuccessMessage] = useState(
    'Password reset request was sent successfully. Please check your email.'
  );
  const [openSuccess, setOpenSuccess] = useState(false);
  const { error: errorIcoEmail, save: saveIcoEmail } = useNewMoralisObject('IcoEmail');

  const [authError, setAuthError] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(false);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState('Login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //------------------------------------------------

  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email().label('Email'),
    password: Yup.string().required().min(8).label('Password')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      type: ''
    },
    validationSchema,
    onSubmit: async ({ email, password, type }, { resetForm }) => {
      if (type === 'signup') {
        try {
          const username = email;
          const { address, encryptedWallet } = await Moralis.Cloud.run('createWallet');
          await Moralis.Cloud.run('watchWalletAddress', { address: address.toLowerCase() });
          await saveIcoEmail({ email });
          await signup(username, password, email, {
            youwhoEthAddress: address.toLowerCase(),
            youwhoEthAddressEnc: encryptedWallet
          });
        } catch (error) {
          console.log(error);
        }
      } else if (type === 'login') {
        try {
          setEmail(email);
          await login(email, password);
        } catch (error) {
          console.log(error);
        }
      }
    }
  });

  const { errors, touched, handleSubmit, handleChange, setFieldValue } = formik;

  //------------------------------------------------

  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  const handleResetPassword = () => {
    Moralis.User.requestPasswordReset(email)
      .then(() => {
        setOpenSuccess(true);
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const metaMaskInstalled = async () => {
    const installed = await Moralis.isMetaMaskInstalled();
    setIsMetaMaskInstalled(installed);
  };

  useEffect(() => {
    metaMaskInstalled();
  }, []);

  useEffect(() => {
    if (user) isWalletWatched();
  }, [user]);

  const isWalletWatched = async () => {
    if (user && user?.attributes?.youwhoEthAddress) {
      // console.log(user.attributes.youwhoEthAddress);
      const { eth, bsc } = await Moralis.Cloud.run('isWalletWatched', {
        address: user.attributes.youwhoEthAddress
      });
      // console.log('eth watched: ', eth, 'bsc watched: ', bsc);

      if (!eth || !bsc) {
        await Moralis.Cloud.run('watchWalletAddress', { address: user.attributes.youwhoEthAddress });
        // console.log('eth watched !: ', eth, 'bsc watched !: ', bsc);
      }
    }
  };

  const setYouwhoEthAddress = async () => {
    const { address, encryptedWallet } = await Moralis.Cloud.run('createWallet');

    try {
      await Moralis.Cloud.run('watchWalletAddress', { address: address.toLowerCase() });
      await setUserData({
        youwhoEthAddress: address.toLowerCase(),
        youwhoEthAddressEnc: encryptedWallet
      });
      console.log('ran set add');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user && !user.attributes.youwhoEthAddress && !user.attributes.youwhoEthAddressEnc) setYouwhoEthAddress();
  }, [user]);

  if (isAuthenticated) {
    return (
      <LoadingButton
        onClick={() => {
          handleClose();
          logout();
        }}
        loading={false}
        fullWidth
        color="primary"
        variant="outlined"
        size="large"
        startIcon={<Icon icon="heroicons-outline:logout" width={20} height={20} />}
        sx={{
          height: 56,
          fontSize: { xs: '1rem', sm: '1.2rem' },
          width: { xs: '100%', sm: '400px' }
        }}
      >
        Logout {getEllipsisTxt(account)}
      </LoadingButton>
    );
  }

  const handleAuth = async (provider = 'metamask') => {
    try {
      setAuthError(null);
      setIsAuthenticating(true);

      // Enable web3 to get user address and chain
      await enableWeb3({ throwOnError: true, provider });
      const { account, chainId } = Moralis;

      if (!account) {
        throw new Error('Connecting to chain failed, as no connected account was found');
      }
      if (!chainId) {
        throw new Error('Connecting to chain failed, as no connected chain was found');
      }

      // Get message to sign from the auth api
      const { message } = await Moralis.Cloud.run('requestMessage', {
        address: account,
        chain: parseInt(chainId, 16),
        networkType: 'evm'
      });

      // Authenticate and login via parse
      await authenticate({
        signingMessage: message,
        throwOnError: true
      });
      handleClose();
    } catch (error) {
      setAuthError(error);
    } finally {
      setIsAuthenticating(false);
    }
  };

  return (
    <>
      <AlertMessage open={openSuccess} setOpen={setOpenSuccess} message={successMessage} severity="success" />

      <LoadingButton
        onClick={handleOpen}
        loading={open}
        fullWidth
        color="primary"
        variant="contained"
        size="large"
        startIcon={<Icon icon="heroicons-outline:login" width={24} height={24} />}
        sx={{
          height: 56,
          fontSize: { xs: '1rem', sm: '1.2rem' },
          color: 'white',
          width: { xs: '100%', sm: '400px' }
        }}
      >
        {buttonText}
      </LoadingButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={[{ ...style }, { width: { xs: '90%', sm: '400px' }, minHeight: '300px' }]}>
          <Box sx={{ paddingBottom: '10px' }}>
            <img alt="YOUWHO logo" src="/static/logos/svg/youwho-teal_text-long-alt.svg" width={570} />
          </Box>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            variant="fullWidth"
          >
            <Tab value="Signup" label="Signup" />
            <Tab value="Login" label="Login" />
            {isMetaMaskInstalled && <Tab value="MetaMask" label="MetaMask" />}
          </Tabs>
          {authError && (
            <Box sx={{ paddingTop: '10px' }}>
              <Typography color="error.main" variant="subtitle2" fontWeight="500" align="center">
                {authError.message}
              </Typography>
            </Box>
          )}
          <FormikProvider value={formik}>
            <Form>
              {tab === 'Signup' && (
                <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ marginTop: '20px' }}>
                  <TextField
                    hiddenLabel
                    name="email"
                    type="text"
                    label="Email"
                    onChange={handleChange}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    sx={{ width: '100%' }}
                  />

                  <TextField
                    hiddenLabel
                    name="password"
                    type="password"
                    label="Password"
                    onChange={handleChange}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    sx={{ width: '100%' }}
                  />

                  <LoadingButton
                    variant="contained"
                    type="submit"
                    size="large"
                    color="secondary"
                    onClick={(e) => {
                      setFieldValue('type', 'signup');
                      handleSubmit(e);
                    }}
                    sx={{
                      height: 56,
                      fontSize: { xs: '1rem', sm: '1.2rem' },
                      color: 'white',
                      width: { xs: '100%' }
                    }}
                    loading={isAuthenticating}
                    startIcon={<Icon icon="carbon:user-admin" width={24} height={24} />}
                  >
                    Signup
                  </LoadingButton>
                </Stack>
              )}
              {tab === 'Login' && (
                <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ marginTop: '20px' }}>
                  <TextField
                    hiddenLabel
                    name="email"
                    type="text"
                    label="Email"
                    onChange={handleChange}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    sx={{ width: '100%' }}
                  />

                  <TextField
                    hiddenLabel
                    name="password"
                    type="password"
                    label="Password"
                    onChange={handleChange}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    sx={{ width: '100%' }}
                  />
                  {authError && <Button onClick={handleResetPassword}>Forgot password? Click here to reset it.</Button>}

                  <LoadingButton
                    variant="contained"
                    size="large"
                    onClick={(e) => {
                      setFieldValue('type', 'login');
                      handleSubmit(e);
                    }}
                    type="submit"
                    sx={{
                      height: 56,
                      fontSize: { xs: '1rem', sm: '1.2rem' },
                      color: 'white',
                      width: { xs: '100%' }
                    }}
                    loading={isAuthenticating}
                    startIcon={<Icon icon="heroicons-outline:login" width={24} height={24} />}
                  >
                    Login
                  </LoadingButton>
                </Stack>
              )}
            </Form>
          </FormikProvider>
          {tab === 'MetaMask' && (
            <Stack flex={1} alignItems="center" justifyContent="center">
              <Box p="20px">
                <img alt="metamask logo" src="/static/icons/metamask.svg" height={150} />
              </Box>
              <LoadingButton
                variant="contained"
                size="large"
                color="warning"
                onClick={async () => {
                  try {
                    await dispatch(setIcoProvider(''));
                    await handleAuth();
                    // await authenticate({ chainId: icoSettings.networkKey, signingMessage: icoSettings.signingMessage });
                  } catch (error) {
                    console.log(error);
                  }
                }}
                sx={{
                  height: 56,
                  fontSize: { xs: '1rem', sm: '1.2rem' },
                  color: 'white',
                  width: { xs: '100%' }
                }}
                loading={isAuthenticating}
                startIcon={<Icon icon="fluent:wallet-48-regular" width={28} height={28} />}
              >
                Connect MetaMask
              </LoadingButton>
            </Stack>
          )}
        </Stack>
      </Modal>
    </>
  );
}
