import { createSlice } from '@reduxjs/toolkit';
// dayjs
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
// web3
import web3 from 'web3';
// moralis
import { Moralis } from '../rootReducer';
// dispatch
import { dispatch } from '../store';

dayjs.extend(relativeTime);

// ----------------------------------------------------------------------

const { BN } = web3.utils.BN;

const initialState = {
  isLoading: false,
  error: null,
  boughtTokens: [],
  totalBoughtTokens: 0,
  totalBnbInvested: 0,
  userRewards: null,
  account: '',
  stakingWallets: [],
  totalStaked: [],
  vestingWallets: [],
  totalVested: 0,
  unstakeWallet: null,
  withdrawHistory: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ICO CONTRACT BOUGHT TOKENS EVENTS FOR USER
    getBoughtTokensSuccess(state, action) {
      state.isLoading = false;
      state.boughtTokens = action.payload;
    },

    // SET TOTAL BOUGHT TOKENS FOR USER
    setTotalBoughtTokensSuccess(state, action) {
      state.isLoading = false;
      state.totalBoughtTokens = action.payload;
    },

    // SET TOTAL BNB INVESTED BY USER
    setTotalBnbInvestedSuccess(state, action) {
      state.isLoading = false;
      state.totalBnbInvested = action.payload;
    },

    // SET STAKING WALLETS FOR USER
    setStakingWalletsSuccess(state, action) {
      state.isLoading = false;
      state.stakingWallets = action.payload;
    },

    // SET TOTAL STAKED FOR USER
    setTotalStakedSuccess(state, action) {
      state.isLoading = false;
      state.totalStaked = action.payload;
    },

    // SET UNSTAKED WALLET FOR USER
    setUnstakeWalletSuccess(state, action) {
      state.isLoading = false;
      state.unstakeWallet = action.payload;
    },

    // SET UNSTAKED WALLET FOR USER
    setWithdrawHistorySuccess(state, action) {
      state.isLoading = false;
      state.withdrawHistory = action.payload;
    },

    // SET VESTING WALLETS FOR USER
    setVestingWalletsSuccess(state, action) {
      state.isLoading = false;
      state.vestingWallets = action.payload;
    },

    // SET TOTAL VESTED FOR USER
    setTotalVestedSuccess(state, action) {
      state.isLoading = false;
      state.totalVested = action.payload;
    },

    // GET USER EMAIL REFERRALS
    getUserRewardsSuccess(state, action) {
      state.isLoading = false;
      state.userRewards = action.payload;
    },

    // SET ETH WALLET ACCOUNT VALUE
    setAccountSuccess(state, action) {
      state.isLoading = false;
      state.account = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------

export function getBoughtTokens(contractAddress, walletAddress, boughtTokensClass = 'BoughtTokens') {
  return async () => {
    if (contractAddress && walletAddress) {
      dispatch(slice.actions.startLoading());
      try {
        const query = new Moralis.Query(boughtTokensClass);
        query.equalTo('address', contractAddress.toLowerCase());
        query.equalTo('buyer', walletAddress.toLowerCase());
        query.descending('block_timestamp');
        const results = await query.find();
        // console.log('results from getbought tokens', results);
        const response = results.map((x) => x.attributes);
        dispatch(slice.actions.getBoughtTokensSuccess(response));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    } else {
      dispatch(slice.actions.getBoughtTokensSuccess([]));
    }
  };
}

// ----------------------------------------------------------------------

export function setTotalBoughtTokens(boughtTokens) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let totalInvested = 0;
      let totalBnbInvested = 0;
      for (let i = 0; i < boughtTokens.length; i += 1) {
        totalInvested += Number(boughtTokens[i].youBought);
        totalBnbInvested += Number(boughtTokens[i].tokensSpent);
      }
      dispatch(slice.actions.setTotalBoughtTokensSuccess(totalInvested));
      dispatch(slice.actions.setTotalBnbInvestedSuccess(totalBnbInvested));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setVestingWalletsR(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const walletEvents = [];
      for (let i = 0; i < data.length; i += 1) {
        //
        const owner = data[i].attributes.address;
        const eventDate = data[i].attributes.createdDate;
        const tokenContract = data[i].attributes.tokenAddress;
        const amount = data[i].attributes.newAmount;
        const { unlockDate, index, updatedAt, transaction_hash: tx } = data[i].attributes;
        const event =
          data[i].attributes.reason === '0'
            ? 'Wallet Created'
            : (() => (data[i].attributes.reason === '1' ? 'Wallet Edited' : 'Wallet Claimed'))();

        const walletEvent = {
          amount,
          event,
          eventDate,
          index,
          owner,
          tokenContract,
          tx,
          updatedAt,
          unlockDate
        };

        if (event === 'Wallet Created') {
          walletEvents[index] = [walletEvent];
        } else {
          walletEvents[index].push(walletEvent);
        }
        //
      }

      const vestingWallets = [];
      let totalVested = 0;

      for (let i = 0; i < walletEvents.length; i += 1) {
        //
        const walletObj = {
          unlockDate: walletEvents[i][walletEvents[i].length - 1].unlockDate,
          tokenSymbol: 'YOU',
          tokenName: 'youwho',
          amount: walletEvents[i][walletEvents[i].length - 1].amount,
          canClaim: walletEvents[i][walletEvents[i].length - 1].unlockDate <= dayjs().unix(),
          claimed: walletEvents[i][walletEvents[i].length - 1].event === 'Wallet Claimed',
          wallet: walletEvents[i],
          index: walletEvents[i][walletEvents[i].length - 1].index
        };

        vestingWallets.push(walletObj);
        totalVested += Number(walletObj.amount);
        //
      }
      // console.log('vestingWallets fro user slice : ', vestingWallets);

      dispatch(slice.actions.setTotalVestedSuccess(totalVested));
      dispatch(slice.actions.setVestingWalletsSuccess(vestingWallets));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setStakingWalletsR(data = [], cryptoArray = []) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      const sortByTokens = [];
      const largestIndex = [];
      for (let i = 0; i < cryptoArray.length; i += 1) {
        const token = [];
        for (let j = 0; j < data.length; j += 1) {
          if (data[j].attributes.tokenAddress === cryptoArray[i].address) {
            token.push(data[j].attributes);
            if (!largestIndex[i] || largestIndex[i] < data[j].attributes.index)
              largestIndex[i] = Number(data[j].attributes.index);
          }
        }
        sortByTokens[i] = token;
      }

      const latestWallets = [];

      for (let i = 0; i < cryptoArray.length; i += 1) {
        const wallets = [];
        for (let j = 0; j <= largestIndex[i]; j += 1) {
          const obj = sortByTokens[i].find((e) => Number(e.index) === j);
          if (obj.amount > 0) wallets.push(obj);
        }
        latestWallets[i] = wallets;
      }

      const totalStakedArray = [];

      for (let i = 0; i < cryptoArray.length; i += 1) {
        let totalStaked = new BN(0);
        if (latestWallets[i].length > 0) {
          for (let j = 0; j < latestWallets[i].length; j += 1) {
            totalStaked = totalStaked.add(new BN(latestWallets[i][j].amount));
          }
        }
        totalStakedArray[i] = totalStaked;
      }

      dispatch(slice.actions.setStakingWalletsSuccess(latestWallets));
      dispatch(slice.actions.setTotalStakedSuccess(totalStakedArray));
    } catch (error) {
      console.log('setStakingWalletsR', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setUnstakeWalletR(data = [], cryptoArray = []) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      const unstakeWallet = [];
      for (let i = 0; i < cryptoArray.length; i += 1) {
        const wallet = data.find((e) => e.attributes.tokenAddress === cryptoArray[i].address);
        unstakeWallet[i] = wallet && wallet.attributes ? wallet.attributes : undefined;
      }
      // console.log('unstakeWallet', unstakeWallet);
      dispatch(slice.actions.setUnstakeWalletSuccess(unstakeWallet));
    } catch (error) {
      console.log('setUnstakeWalletR', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setWithdrawHistoryR(data = [], cryptoArray = []) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      const withdrawHistory = [];
      for (let i = 0; i < cryptoArray.length; i += 1) {
        const historyUnformatted = data.filter((e) => e.attributes.tokenAddress === cryptoArray[i].address);
        const history =
          historyUnformatted && historyUnformatted.length > 0 ? historyUnformatted.map((e) => e.attributes) : undefined;
        withdrawHistory[i] = history;
      }
      dispatch(slice.actions.setWithdrawHistorySuccess(withdrawHistory));
    } catch (error) {
      console.log('setWithdrawHistoryR', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserRewards(email) {
  return async () => {
    if (email && email !== '') {
      dispatch(slice.actions.startLoading());
      try {
        const query = new Moralis.Query('IcoEmail');
        const result = await query.find();

        const index = result.map((e) => e.attributes.email.toLowerCase()).indexOf(email.toLowerCase());
        const userObject = index >= 0 ? result[index] : undefined;

        if (userObject) {
          const userId = userObject.id;
          const referrals = result.filter((x) => x.attributes.referral === userId);

          const getUserRewardsObject = { object: userObject, referrals };

          dispatch(slice.actions.getUserRewardsSuccess(getUserRewardsObject));
        }
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    } else {
      dispatch(slice.actions.getUserRewardsSuccess(null));
    }
  };
}

// ----------------------------------------------------------------------

export function setAccountRedux(value) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setAccountSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
