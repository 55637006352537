import { Icon } from '@iconify/react';
// routes
import { YOUWHO_HOMEPAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  { title: 'Home', path: YOUWHO_HOMEPAGE, icon: <Icon icon="bx:home-alt" {...ICON_SIZE} /> },
  // {
  //   title: 'Vesting Schedule',
  //   path: `${YOUWHO_HOMEPAGE}#vesting`,
  //   icon: <Icon icon="bi:calendar-week" {...ICON_SIZE} />
  // },
  // {
  //   title: 'How To Buy',
  //   path: `${YOUWHO_HOMEPAGE}#ico`,
  //   icon: <Icon icon="entypo:wallet" {...ICON_SIZE} />
  // },
  {
    title: 'Staking',
    path: '/staking',
    icon: <Icon icon="carbon:crop-growth" {...ICON_SIZE} />
  },
  {
    title: 'Vesting',
    path: '/vesting',
    icon: <Icon icon="simple-line-icons:wallet" {...ICON_SIZE} />
  }
  // {
  //   title: 'ICO',
  //   path: '/ico',
  //   icon: <Icon icon="bi:fingerprint" {...ICON_SIZE} />
  // }
  // {
  //   title: 'pre',
  //   path: '/pre',
  //   icon: <Icon icon="eva:cube-fill" {...ICON_SIZE} />
  // }
];

export default menuConfig;
