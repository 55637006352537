const TRANSITION_ENTER = {
  duration: 2,
  repeat: Infinity,
  type: 'spring',
  stiffness: 100
};

export const varJump = {
  animate: {
    x: [0, 1, 2, 3, 4, 3, 2, 1, 0],
    y: [0, -0.75, -1, -0.75, 0, -0.75, -1, -0.75, 0],
    opacity: 1,
    transition: TRANSITION_ENTER
  }
};
