import { Outlet } from 'react-router-dom';
//
import MainNavbar from './MainNavbar';
import MainFooter from './MainFooter';
// import LandingPage from '../../pages/LandingPage';

// ----------------------------------------------------------------------

export default function MainLayout() {
  return (
    <>
      <MainNavbar />
      {/* <LandingPage /> */}
      <Outlet />
      <MainFooter />
    </>
  );
}
