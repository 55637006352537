import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
// material
import { Box } from '@mui/material';
import MotionContainer from './animate/MotionContainer';
import { varWrapEnter, varJump, varFadeInLeft } from './animate';

// ----------------------------------------------------------------------

LogoYouwho.propTypes = {
  sx: PropTypes.object,
  animate: PropTypes.string,
  varsIn: PropTypes.string,
  sxLogo: PropTypes.object
};

export default function LogoYouwho({ sx, varsIn = varFadeInLeft, animate = 'no', sxLogo }) {
  return (
    <MotionContainer open initial="initial" animate="animate" variants={varWrapEnter}>
      <motion.div variants={varsIn}>
        <Box sx={{ width: 50, height: 50, ...sx }}>
          <Box
            component={motion.img}
            animate={animate}
            variants={varJump}
            alt="youwho logo"
            src="/static/logos/svg/youwho-teal_logo-pill.svg"
            sx={{
              width: 50,
              height: 50,
              ...sxLogo
            }}
          />
        </Box>
      </motion.div>
    </MotionContainer>
  );
}
