import React from 'react';
import * as Add2Calendar from 'add2calendar';
import { Button, Link, Stack, Modal, Typography, Card, Fade, Grid, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: 300, sm: 580 },
  height: { xs: '95%', sm: 'auto' },
  overflow: 'auto'
};

const singleEventArgs = {
  title: '$YOU ICO',
  start: 'January 19, 2022 05:00 UTC',
  end: 'March 5, 2022 05:00 UTC',
  location: 'https://ico.youwho.io',
  description: "Head to https://ico.youwho.io to get in early for youwho's $YOU ICO!"
};

const singleEvent = new Add2Calendar(singleEventArgs);

const googleUrl = singleEvent.getGoogleUrl();
const calUrl = singleEvent.getICalUrl();
const outlookUrl = singleEvent.getOutlookUrl();
const yahooUrl = singleEvent.getYahooUrl();

export default function AddToCalendar() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Stack alignItems="center">
      <Button
        endIcon={<Icon icon="gridicons:time" height="26" width="26" />}
        variant="contained"
        size="large"
        onClick={handleOpen}
        sx={{ height: 56, width: { xs: '100%', sm: '70%', md: '50%' }, fontSize: { xs: '1rem', sm: '1.2rem' } }}
      >
        add $YOU ICO to Cal.
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Card sx={style}>
            <Grid container>
              <Grid item xs={12} sm={5}>
                <Stack
                  sx={{ height: '100%', py: { xs: 5, sm: 3 } }}
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                >
                  <Link
                    href={googleUrl}
                    sx={{ '&:hover': { textDecoration: 'none', color: 'secondary.main' } }}
                    target="blank"
                  >
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                      <Icon icon="flat-color-icons:google" width="30" height="30" />
                      <Typography variant="h5">Google</Typography>
                    </Stack>
                  </Link>
                  <Link
                    href={calUrl}
                    sx={{ '&:hover': { textDecoration: 'none', color: 'secondary.main' } }}
                    target="blank"
                  >
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                      <Icon icon="logos:apple" width="30" height="30" />
                      <Typography variant="h5">iCal</Typography>
                    </Stack>
                  </Link>
                  <Link
                    href={outlookUrl}
                    sx={{ '&:hover': { textDecoration: 'none', color: 'secondary.main' } }}
                    target="blank"
                  >
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                      <Icon icon="vscode-icons:file-type-outlook" width="30" height="30" />
                      <Typography variant="h5">Outlook</Typography>
                    </Stack>
                  </Link>
                  <Link
                    href={yahooUrl}
                    sx={{ '&:hover': { textDecoration: 'none', color: 'secondary.main' } }}
                    target="blank"
                  >
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                      <Icon icon="logos:yahoo" width="50" height="30" />
                      <Typography variant="h5">Yahoo</Typography>
                    </Stack>
                  </Link>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Stack sx={{ paddingTop: { xs: '0', sm: '60px' }, overflow: 'hidden' }} justifyContent="flex-end">
                  <img alt="youwho Stays" src="/static/media/img/png/iphone-12-hand-hold-service-search-teal.png" />
                </Stack>
              </Grid>
            </Grid>
            <Stack sx={{ position: 'absolute', width: '100%', top: 0, left: 0, zIndex: '-2' }}>
              <img alt="youwho programmer" src="/static/media/img/svg/youwho-ico-top-bg_tall.svg" />
            </Stack>
            <Stack sx={{ position: 'absolute', top: 0, right: 0, p: 2 }}>
              <IconButton aria-label="close" onClick={handleClose}>
                <Icon icon="ph:x-bold" width="20" height="20" />
              </IconButton>
            </Stack>
          </Card>
        </Fade>
      </Modal>
    </Stack>
  );
}
