import { useState, useEffect } from 'react';
import { useMoralisQuery } from 'react-moralis';

export default function MaxSupply() {
  const [supply, setSupply] = useState(51000000000);
  const { data } = useMoralisQuery('IcoSettings');

  useEffect(() => {
    if (data.length > 0) setSupply(Number(data[0].attributes.maxTokens));
  }, [data]);
  return <pre>{supply}</pre>;
}
