// react router
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
// components
import Page from '../components/Page';

LandingPage.propTypes = {
  title: PropTypes.string
};

// ----------------------------------------------------------------------

export default function LandingPage({ title }) {
  return (
    <Page title={title} id="move_top">
      <Outlet />
    </Page>
  );
}
