import React, { useState, useEffect } from 'react';
import { useMoralis, useMoralisQuery, useChain } from 'react-moralis';
// icons
import { Icon } from '@iconify/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// mui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Typography,
  Stack,
  Box,
  useTheme,
  Grid,
  Card,
  Link,
  Tooltip,
  List,
  ListItem,
  Divider
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// dayjs
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { setVestingWalletsR } from '../../../../store/slices/user';
// helpers
import { getEllipsisTxt } from '../../../../utils/formatters';
// components
import LoadingScreen from '../../../LoadingScreen';
import LoginModal from '../../../forms/LoginModal';

dayjs.extend(relativeTime);

// ---------------------------------------------

export default function MainVesting() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch();
  const {
    isLoading: isLoadingUser,
    account,
    vestingWallets: vestingWalletsR,
    totalVested
  } = useSelector((state) => state.user);
  const { vestingWalletClass, isLoading, icoSettings, tokenVesting } = useSelector((state) => state.ico);
  const tokenVestingAbi = JSON.parse(JSON.stringify(tokenVesting.abi));
  const [tokenVestingContract, setTokenVestingContract] = useState(null);
  const { isAuthenticated, enableWeb3, isWeb3Enabled, web3 } = useMoralis();
  const { switchNetwork, chainId, chain } = useChain();
  const [vestedAmount, setVestedAmount] = useState(0);
  const [vestingWallets, setVestingWallets] = useState([]);
  const [isLoadingClaim, setIsLoadingClaim] = useState(false);

  const {
    data: dataVesting,
    error: errorVesting,
    isLoading: isLoadingVesting
  } = useMoralisQuery(vestingWalletClass, (query) => query.equalTo('owner', account.toLowerCase()), [account], {
    live: true
  });

  useEffect(() => {
    if (!isWeb3Enabled) enableWeb3();
  }, [isWeb3Enabled]);

  const setContract = async () => {
    try {
      const netId = String(chain.networkId);
      console.log('tokenVesting.networks[netId]', tokenVesting.networks[netId]);
      const tokenVestingAddress = tokenVesting.networks[netId]
        ? tokenVesting.networks[netId].address.toLowerCase()
        : undefined;
      // console.log('tokenVestingAddress', tokenVestingAddress);
      // console.log('tokenVestingAbi', tokenVestingAbi);
      if (tokenVestingAddress) setTokenVestingContract(new web3.eth.Contract(tokenVestingAbi, tokenVestingAddress));
    } catch (e) {
      console.log(e);
    }
  };

  const handleClaimWallet = async (index) => {
    setIsLoadingClaim(true);
    try {
      if (tokenVestingContract) {
        // console.log('index', index);
        await tokenVestingContract.methods.claimTokens(index).send({ from: account });
      } else {
        throw new Error('Contract not available.');
      }
      setIsLoadingClaim(false);
    } catch (error) {
      setIsLoadingClaim(false);
      console.log('claimWallet error : ', error);
    }
  };

  useEffect(() => {
    if (tokenVesting && isWeb3Enabled) setContract();
    // console.log(isWeb3Enabled, chainId);
  }, [isWeb3Enabled, tokenVesting, chainId]);

  useEffect(() => {
    if (dataVesting && dataVesting.length > 0) {
      dispatch(setVestingWalletsR(dataVesting));
    } else {
      dispatch(setVestingWalletsR([]));
    }
  }, [dataVesting, dispatch]);

  useEffect(() => {
    setVestingWallets(vestingWalletsR);
  }, [vestingWalletsR]);

  useEffect(() => {
    setVestedAmount(totalVested);
  }, [totalVested]);

  return (
    <Stack spacing={4} p={2} sx={{ width: '100%' }} alignItems="center">
      <Box sx={{ height: { xs: 75, sm: 100 } }}>
        <img alt="YOUWHO logo" src="/static/logos/svg/youwho-teal_text-long-alt.svg" width={570} height={100} />
      </Box>

      <Typography variant="h2" fontWeight="300">
        Vesting Portal Open
      </Typography>

      <LoginModal buttonText="Login / Connect" />

      {icoSettings &&
        chainId !== icoSettings.networkKey &&
        window.ethereum &&
        isAuthenticated && ( // chainId !== icoSettings.networkKey
          <Button
            size="large"
            variant="outlined"
            color="secondary"
            onClick={() => switchNetwork(icoSettings.networkKey)}
            startIcon={<Icon icon="simple-icons:binance" width={20} height={20} />}
            sx={{ width: { xs: '100%', sm: '400px' }, textTransform: 'none' }}
          >
            Switch to BSC
          </Button>
        )}
      {isLoading || isLoadingUser || isLoadingVesting ? (
        <Stack spacing={0} alignItems="center" justifyContent="center" height="300px">
          <LoadingScreen />
        </Stack>
      ) : (
        <>
          {isAuthenticated && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                  <Stack>
                    <Stack flexDirection="row" justifyContent="center" alignItems="center">
                      <Typography variant="h3" textAlign="center" fontWeight="300">
                        {isAuthenticated ? getEllipsisTxt(account, 8) : '...'}
                      </Typography>
                      <CopyToClipboard text={account}>
                        <Typography>
                          <IconButton>
                            <Icon icon="heroicons-outline:clipboard-copy" />
                          </IconButton>
                        </Typography>
                      </CopyToClipboard>
                    </Stack>
                    <Stack flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="center">
                      <Typography variant="h2">
                        {(vestedAmount / 10 ** 18).toLocaleString('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })}
                      </Typography>
                      <img
                        // duration={0}
                        alt="$YOU"
                        src="/static/logos/svg/youwho-logos_token-alt.svg"
                        width={smUp ? '120px' : '85px'}
                        style={{ margin: '0px 8px' }}
                      />
                      <Typography variant="h3" fontWeight="300">
                        Vested
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Vested
                smUp={smUp}
                vestingWallets={vestingWallets}
                isLoadingClaim={isLoadingClaim}
                handleClaimWallet={handleClaimWallet}
              />
            </>
          )}
        </>
      )}
    </Stack>
  );
}

// ---------------------------------------------

function Vested({ smUp, vestingWallets, isLoadingClaim, handleClaimWallet }) {
  const [expanded, setExpanded] = useState('panel0');
  const { web3 } = useMoralis();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Stack flexDirection={smUp ? 'row' : 'column'} alignItems={smUp ? 'center' : 'flex-start'}>
        <Typography variant="h3">Vesting Wallets</Typography>
        {/* <Icon icon="ic:baseline-history-edu" color="#222" width="40" height="40" /> */}
      </Stack>
      <Box sx={{ width: '100%' }}>
        {vestingWallets.map((x, i) => (
          <Accordion key={i} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${i}bh-content`}
              id={`panel${i}bh-header`}
            >
              <Stack flexDirection="row" alignItems="baseline" justifyContent="space-between" sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ px: '5px' }} textAlign="center">
                  Wallet {i + 1}
                </Typography>
                {x.amount === '0' ? (
                  <Typography variant="h4" color="grey.500" sx={{ px: '5px' }} textAlign="center">
                    {(x.amount / 10 ** 18).toLocaleString('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}{' '}
                    {x.tokenSymbol}
                  </Typography>
                ) : (
                  <Typography variant="h4" color="primary.main" sx={{ px: '5px' }} textAlign="center">
                    {(x.amount / 10 ** 18).toLocaleString('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}{' '}
                    {x.tokenSymbol}
                  </Typography>
                )}
                {x.canClaim && !x.claimed ? (
                  <Typography variant="h5" textAlign="center">
                    Claimable Now
                  </Typography>
                ) : (
                  <>
                    {x.claimed ? (
                      <Typography variant="h5" fontWeight="300" color="grey.500" textAlign="center">
                        Already Claimed
                      </Typography>
                    ) : (
                      <Typography variant="h5" color="grey.500" textAlign="center">
                        Claimable {dayjs.unix(x.unlockDate).fromNow()}
                      </Typography>
                    )}
                  </>
                )}
                <Box />
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack flexDirection="row" justifyContent="center">
                {x.canClaim && !x.claimed ? (
                  <LoadingButton
                    loading={isLoadingClaim}
                    onClick={() => handleClaimWallet(x.index)}
                    size="large"
                    sx={{ fontSize: '20px' }}
                    variant="contained"
                  >
                    Claim Now
                  </LoadingButton>
                ) : (
                  <>
                    {x.claimed ? (
                      <Button size="large" disabled variant="contained">
                        Already Claimed
                      </Button>
                    ) : (
                      <Button size="large" disabled variant="contained">
                        Claim {dayjs.unix(x.unlockDate).fromNow()}
                      </Button>
                    )}
                  </>
                )}
              </Stack>
              <List>
                {x.wallet.map((xw, iw) => (
                  <Stack key={iw} sx={{ width: '100%' }}>
                    <ListItem>
                      <Stack sx={{ width: '100%' }}>
                        <Typography fontWeight="500">
                          Event {iw + 1} : {xw.event}
                        </Typography>
                        <Typography>Updated on : {String(dayjs.unix(xw.eventDate))}</Typography>
                        <Typography>
                          &gt; Amount Claimable :{' '}
                          <b>
                            {(xw.amount / 10 ** 18).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}{' '}
                            {x.tokenSymbol}
                          </b>
                        </Typography>
                        <Typography>
                          &gt; Event Tx :{' '}
                          <Link href={`https://bscscan.com/tx/${xw.tx}`}>
                            {smUp ? xw.tx : getEllipsisTxt(xw.tx, 8)}
                          </Link>
                        </Typography>
                        <Typography>
                          &gt; Unlock Date : <b>{String(dayjs.unix(xw.unlockDate))}</b>
                        </Typography>
                      </Stack>
                    </ListItem>
                    {x.wallet.length - 1 !== iw && <Divider variant="middle" sx={{ my: '5px' }} />}
                  </Stack>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Stack>
  );
}
