import React, { useState, useEffect } from 'react';
import { useMoralisSubscription, useMoralis, useChain, useMoralisQuery } from 'react-moralis';
import { useNavigate } from 'react-router-dom';
// icons
import { Icon } from '@iconify/react';
// mui
import { styled, alpha } from '@mui/material/styles';
import {
  Button,
  IconButton,
  Typography,
  Stack,
  Box,
  useTheme,
  Grid,
  Card,
  Link,
  Tooltip,
  TextField
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// luxon
import { DateTime } from 'luxon';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { getBoughtTokens, getUserRewards, setAccountRedux } from '../../../../store/slices/user';
// helpers
import { getEllipsisTxt } from '../../../../utils/formatters';
// components
import LoadingScreen from '../../../LoadingScreen';
import RegisteredEmail from '../../../forms/RegisteredEmail';
import LoginModal from '../../../forms/LoginModal';
import ReferralForm from '../../../forms/ReferralForm';

// ---------------------------------------------

const GridCard = styled(Card)(({ theme }) => ({
  padding: '20px',
  display: 'flex',
  height: '100%',
  // justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadiusMd,
  border: '1px solid',
  borderColor: alpha(theme.palette.grey[500], 0.3),
  // backgroundColor: alpha(theme.palette.grey[200], 0.1),
  boxShadow: theme.shadows[0]
  // boxShadow: theme.shadows[0]
}));

// ---------------------------------------------

export default function AdminDashboard() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [currentDate, setCurrentDate] = useState(new Date('Wednesday, March 9, 2022 5:00:00 AM')); // 'Wednesday, March 9, 2022 5:00:00 AM'
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    boughtTokens,
    totalBoughtTokens,
    isLoading: isLoadingUser,
    userRewards,
    account,
    totalBnbInvested
  } = useSelector((state) => state.user);
  const { icoContractAddress, boughtTokensClass, isLoading, icoSettings } = useSelector((state) => state.ico);
  const { user, isAuthenticated, refetchUserData, Moralis } = useMoralis();
  const { switchNetwork, chainId } = useChain();
  const [investedAmount, setInvestedAmount] = useState(0);
  const [totalStakingYield, setTotalStakingYield] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [email, setEmail] = useState('');
  const [accountInput, setAccountInput] = useState('');
  const [userId, setUserId] = useState('');
  const [stakingEndDate, setStakingEndDate] = useState(new Date('Wednesday, March 9, 2022 5:00:00 AM'));

  useEffect(() => {
    setInvestedAmount(Number((totalBoughtTokens / 10 ** 18).toFixed(2)));
  }, [totalBoughtTokens]);

  useMoralisSubscription(
    boughtTokensClass,
    (q) => q.equalTo('address', icoContractAddress).equalTo('buyer', account),
    [icoContractAddress, account],
    {
      onCreate: (_) => {
        dispatch(getBoughtTokens(icoContractAddress, account, boughtTokensClass));
      },
      onUpdate: (_) => {
        dispatch(getBoughtTokens(icoContractAddress, account, boughtTokensClass));
      }
    }
  );

  const { data: admin } = useMoralisQuery(
    Moralis.Role,
    (query) => query.equalTo('name', 'coreservices').equalTo('users', user),
    [user]
  );

  // useEffect(() => {
  //   if (user) setEmail(user?.attributes?.email);
  //   if (!user) setEmail('');
  // }, [user]);

  // useEffect(() => {
  //   if (user) refetchUserData();
  // }, [email, user]);

  useEffect(() => {
    if (email) dispatch(getUserRewards(email));
    if (!email) dispatch(getUserRewards(''));
  }, [dispatch, email]);

  const handleSetAccount = () => {
    dispatch(setAccountRedux(accountInput));
  };

  useEffect(() => {
    if (icoSettings) {
      setStakingEndDate(new Date(icoSettings.superStakingEndDate * 1000));
    }
  }, [icoSettings]);

  useEffect(() => {
    if (currentDate > stakingEndDate) setCurrentDate(new Date(icoSettings.superStakingEndDate * 1000));
  }, [currentDate, stakingEndDate]);

  // console.log('stakingEndDate', currentDate);

  return (
    <Stack spacing={4} p={2} sx={{ width: '100%' }} alignItems="center">
      <Box sx={{ height: { xs: 75, sm: 100 } }}>
        <img alt="YOUWHO logo" src="/static/logos/svg/youwho-teal_text-long-alt.svg" width={570} height={100} />
      </Box>

      <LoginModal buttonText="Login / Connect" />
      {admin.length > 0 && (
        <>
          <TextField
            id="input-user-id"
            sx={{ width: '50%' }}
            label="user id"
            variant="outlined"
            onChange={(e) => setUserId(e.target.value)}
          />
          <TextField
            id="input-email"
            sx={{ width: '50%' }}
            label="email"
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            id="input-address"
            sx={{ width: '50%' }}
            label="address"
            variant="outlined"
            onChange={(e) => setAccountInput(e.target.value)}
          />
          <Button
            size="large"
            variant="outlined"
            color="secondary"
            onClick={handleSetAccount}
            sx={{ width: '50%', textTransform: 'none' }}
          >
            set address
          </Button>

          {chainId !== icoSettings.networkKey &&
            window.ethereum &&
            isAuthenticated && ( // chainId !== icoSettings.networkKey
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                onClick={() => switchNetwork(icoSettings.networkKey)}
                startIcon={<Icon icon="simple-icons:binance" width={20} height={20} />}
                sx={{ width: { xs: '100%', sm: '400px' }, textTransform: 'none' }}
              >
                Switch to BSC
              </Button>
            )}
          {isLoading && isLoadingUser ? (
            <Stack spacing={0} alignItems="center" justifyContent="center" height="300px">
              <LoadingScreen />
            </Stack>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                  <Stack>
                    <Typography variant="h3" textAlign="center" fontWeight="300">
                      Total Owned by
                    </Typography>

                    <Stack flexDirection="row" justifyContent="center" alignItems="center">
                      <Typography variant="h3" textAlign="center" fontWeight="300">
                        {isAuthenticated ? getEllipsisTxt(account, 8) : '...'}
                      </Typography>
                      <CopyToClipboard text={account}>
                        <Typography>
                          <IconButton>
                            <Icon icon="heroicons-outline:clipboard-copy" />
                          </IconButton>
                        </Typography>
                      </CopyToClipboard>
                    </Stack>
                    <Typography variant="h2" textAlign="center">
                      {Number(investedAmount + totalStakingYield + totalRewards).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                      <img
                        // duration={0}
                        alt="$YOU"
                        src="/static/logos/svg/youwho-logos_token-alt.svg"
                        width={smUp ? '120px' : '85px'}
                        style={{ display: 'inline-block', top: '4px', position: 'relative', margin: '0px 8px' }}
                      />
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <GridCard>
                    <Typography variant="h5" textAlign="center" fontWeight="300">
                      Invested
                    </Typography>
                    <Typography variant={smUp ? 'h4' : 'h3'} textAlign="center">
                      {Number(investedAmount).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}{' '}
                      <img
                        // duration={0}
                        alt="$YOU"
                        src="/static/logos/svg/youwho-logos_token-alt.svg"
                        width={smUp ? '60px' : '50px'}
                        style={{ display: 'inline-block', top: '2px', position: 'relative', margin: '0px' }}
                      />
                    </Typography>
                  </GridCard>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <GridCard>
                    <Typography variant="h5" textAlign="center" fontWeight="300">
                      Staking Yield
                    </Typography>
                    <Typography variant={smUp ? 'h4' : 'h3'} textAlign="center">
                      {Number(totalStakingYield).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}{' '}
                      <img
                        // duration={0}
                        alt="$YOU"
                        src="/static/logos/svg/youwho-logos_token-alt.svg"
                        width={smUp ? '60px' : '50px'}
                        style={{ display: 'inline-block', top: '2px', position: 'relative', margin: '0px' }}
                      />
                    </Typography>
                  </GridCard>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <GridCard>
                    <Typography variant="h5" textAlign="center" fontWeight="300">
                      Rewards
                    </Typography>
                    <Typography variant={smUp ? 'h4' : 'h3'} textAlign="center">
                      {Number(totalRewards).toLocaleString('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })}
                      {'* '}
                      <img
                        // duration={0}
                        alt="$YOU"
                        src="/static/logos/svg/youwho-logos_token-alt.svg"
                        width={smUp ? '60px' : '50px'}
                        style={{ display: 'inline-block', top: '2px', position: 'relative', margin: '0px' }}
                      />
                    </Typography>
                  </GridCard>
                </Grid>
              </Grid>
              <Box sx={{ height: '0px' }} />
              <Invested
                boughtTokens={boughtTokens}
                currentDate={currentDate}
                setTotalStakingYield={setTotalStakingYield}
                totalBnbInvested={totalBnbInvested}
                smUp={smUp}
              />
              <Rewards
                Moralis={Moralis}
                userId={userId}
                email={email}
                setEmail={setEmail}
                userRewards={userRewards}
                setTotalRewards={setTotalRewards}
              />
              <Box />
              {user && <Referral user={user} />}
            </>
          )}
          <Stack direction="row" sx={{ width: { xs: '100%', sm: '400px' } }}>
            <Button
              size="large"
              sx={{
                height: 56,
                fontSize: { xs: '1rem', sm: '1.2rem' }
              }}
              onClick={() => navigate('../invest')}
            >
              <Icon icon="eva:arrow-ios-back-fill" /> invest
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
}

// ---------------------------------------------

function Invested({ smUp, boughtTokens, currentDate, setTotalStakingYield, totalBnbInvested }) {
  const columns = [
    { field: 'date', headerName: 'age', width: 120 },
    {
      field: 'invested',
      headerName: 'invested',
      flex: 1,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const getInvested = params.getValue(params.id, 'invested');
        return <b>{getInvested} YOU</b>;
      }
    },
    { field: 'price', headerName: 'price', width: 130, align: 'center', headerAlign: 'center' },
    {
      field: 'stakingPercent',
      headerName: 'apy',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false
    },
    {
      field: 'stakingYield',
      headerName: 'staking yield',
      flex: 1,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const getYield = params.getValue(params.id, 'stakingYield');
        return (
          <b>
            {getYield.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}{' '}
            YOU
          </b>
        );
      }
    },
    {
      field: 'txHash',
      headerName: 'tx #',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const getLink = params.getValue(params.id, 'txHash');
        return (
          <Tooltip title={getLink} arrow placement="top">
            <Link href={`https://bscscan.com/tx/${getLink}`} target="blank">
              {getEllipsisTxt(getLink, 5)}
            </Link>
          </Tooltip>
        );
      }
    },
    {
      field: 'txConfirmed',
      headerName: 'confirmed',
      type: 'boolean',
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const txConfirmed = params.getValue(params.id, 'txConfirmed');
        return (
          <Stack alignItems="center" justifyContent="center" sx={{ width: 1, textAlign: 'center' }}>
            {txConfirmed ? (
              <Tooltip title="confirmed" arrow placement="top">
                <Icon icon="eva:checkmark-circle-2-fill" width="20" height="20" color="#00c999" />
              </Tooltip>
            ) : (
              <Tooltip title="unconfirmed" arrow placement="top">
                <Icon icon="eva:close-circle-fill" width="20" height="20" color="#FFB369" />
              </Tooltip>
            )}
          </Stack>
        );
      }
    }
  ];
  const [dataGridHeight, setDataGridHeight] = useState('');
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setDataGridHeight(`${boughtTokens.length === 0 ? 1 * 51 + 200 : boughtTokens.length * 51 + 200}px`);
  }, [boughtTokens]);

  useEffect(() => {
    const rowArr = boughtTokens.map((x, i) => ({
      id: i,
      date: DateTime.fromJSDate(new Date(String(x.block_timestamp))).toRelative(),
      invested: (x.youBought / 10 ** 18).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      price: `$${(x.youUsd / 10 ** 18).toFixed(5)} USD`,
      stakingPercent: `${(Number(x.icoStakingApy) * 100).toFixed(2)} %`,
      stakingYield:
        ((currentDate.getTime() - new Date(String(x.block_timestamp)).getTime()) / 31536000000) *
        Number(x.icoStakingApy) *
        (x.youBought / 10 ** 18),
      txHash: x.transaction_hash,
      txConfirmed: x.confirmed
    }));
    setRows(rowArr);
    if (rowArr.length > 0) {
      let totStaking = 0;
      for (let i = 0; i < rowArr.length; i += 1) {
        totStaking += Number(rowArr[i].stakingYield);
      }
      setTotalStakingYield(totStaking);
    } else {
      setTotalStakingYield(0);
    }
  }, [boughtTokens]);

  return (
    <Stack sx={{ width: '100%' }}>
      <Stack flexDirection={smUp ? 'row' : 'column'} alignItems={smUp ? 'center' : 'flex-start'}>
        <Typography variant="h3">Investment History</Typography>
        <Typography variant="h4" fontWeight="300" sx={{ paddingLeft: { xs: '0', sm: '10px' } }}>
          ({totalBnbInvested && (totalBnbInvested / 10 ** 18).toFixed(2)} BNB)
        </Typography>
        {/* <Icon icon="ic:baseline-history-edu" color="#222" width="40" height="40" /> */}
      </Stack>
      <Box sx={{ width: '100%', height: dataGridHeight }}>
        <DataGrid
          disableSelectionOnClick
          rows={rows}
          rowHeight={50}
          columns={columns}
          components={{
            Toolbar: GridToolbar
          }}
        />
      </Box>
    </Stack>
  );
}

//----------------------------------------------------------------

function Rewards({ Moralis, email, setEmail, userId, userRewards, setTotalRewards }) {
  const [compiledRewards, setCompiledRewards] = useState(null);
  const [dataGridHeight, setDataGridHeight] = useState('');
  const [rows, setRows] = useState([]);
  const [referrals, setReferrals] = useState();
  const [referred, setReferred] = useState();

  const getReferrals = async () => {
    if (userId) {
      const refs = await Moralis.Cloud.run('getReferrersReferrals', { userId });
      if (refs) setReferrals(refs);
      const refb = await Moralis.Cloud.run('getBuyersReferrals', { userId });
      if (refb) setReferred(refb);
    }
    if (!userId) {
      setReferrals(null);
      setReferred(null);
    }
  };

  useEffect(() => {
    getReferrals();
  }, [userId]);

  useEffect(() => {
    try {
      const rewardsArr = [];

      if (referred) rewardsArr.push(referred);
      if (referrals && referrals.length > 0) {
        rewardsArr.push(...referrals);
      }
      if (userRewards?.object?.attributes?.airdrops) rewardsArr.push(...userRewards.object.attributes.airdrops);
      setCompiledRewards(rewardsArr);
      if (rewardsArr.length > 0) {
        let totRewards = 0;
        for (let i = 0; i < rewardsArr.length; i += 1) {
          totRewards += +rewardsArr[i].reward;
        }
        setTotalRewards(totRewards);
      } else {
        setTotalRewards(0);
      }
    } catch (e) {
      console.error(e);
    }
  }, [userRewards, referrals, referred]);

  const columns = [
    { field: 'source', headerName: 'source', flex: 1, minWidth: 250, align: 'center', headerAlign: 'center' },
    {
      field: 'reward',
      headerName: 'reward',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const reward = params.getValue(params.id, 'reward');
        return <b>{reward} YOU</b>;
      }
    },
    {
      field: 'link',
      headerName: 'link',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const link = params.getValue(params.id, 'link');
        return (
          <>
            {link === '#na' ? (
              '-'
            ) : (
              <Tooltip title={link} arrow placement="top">
                <Link href={link} target="blank">
                  {link}
                </Link>
              </Tooltip>
            )}
          </>
        );
      }
    },
    {
      field: 'confirmed',
      headerName: 'confirmed',
      type: 'boolean',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const confirmed = params.getValue(params.id, 'confirmed');
        return (
          <Stack alignItems="center" justifyContent="center" sx={{ width: 1, textAlign: 'center' }}>
            {confirmed ? (
              <Tooltip title="confirmed" arrow placement="top">
                <Icon icon="eva:checkmark-circle-2-fill" width="20" height="20" color="#00c999" />
              </Tooltip>
            ) : (
              <Tooltip title="unconfirmed" arrow placement="top">
                <Icon icon="eva:close-circle-fill" width="20" height="20" color="#FFB369" />
              </Tooltip>
            )}
          </Stack>
        );
      }
    }
  ];

  useEffect(() => {
    if (compiledRewards && compiledRewards.length > 0) {
      setDataGridHeight(`${compiledRewards.length * 51 + 200}px`);
    } else {
      setDataGridHeight(`${1 * 51 + 200}px`);
    }
  }, [compiledRewards]);

  useEffect(() => {
    try {
      if (compiledRewards) {
        const rowArr = compiledRewards.map((x, i) => ({
          id: i,
          source: x.source,
          reward: x.reward.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }),
          link: x.link,
          confirmed: x.confirmed
        }));
        setRows(rowArr);
      }
    } catch (e) {
      console.error(e);
    }
  }, [compiledRewards]);

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Typography variant="h3">Rewards & Airdrops</Typography>
      <Typography variant="subtitle2">
        (*Rewards will be confirmed once you and/or the person you referred has invested at least 1 BNB)
      </Typography>
      <Stack alignItems="center">
        {!userId ? (
          <Stack spacing={2} alignItems="center">
            <Typography variant="h4" textAlign="center" fontWeight="300">
              You must login or connect to MetaMask before being able to check your rewards & airdrops.
            </Typography>
            <LoginModal buttonText="Login / Connect" />
          </Stack>
        ) : (
          <>
            {!email ? (
              <RegisteredEmail setEmail={setEmail} />
            ) : (
              <Box sx={{ width: '100%', height: dataGridHeight }}>
                <DataGrid
                  disableSelectionOnClick
                  rows={rows}
                  rowHeight={50}
                  columns={columns}
                  components={{
                    Toolbar: GridToolbar
                  }}
                />
              </Box>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
}

function Referral() {
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Typography variant="h3">Referrals</Typography>
      <ReferralForm />
    </Stack>
  );
}
