import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Slider, Tooltip, Skeleton } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';

// ---------------------------------------------

const ProgressTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.primary.dark
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.primary.dark
    }
  })
);

// ---------------------------------------------

function valuetext(value) {
  return `${value} $YOU`;
}

// ---------------------------------------------

export default function IcoProgressBar() {
  const { icoSettings } = useSelector((state) => state.ico);
  const [marks, setMarks] = useState([]);
  const [openTooltip, setOpenTooltip] = useState(false);

  const formatRate = () => {
    const { rate } = icoSettings;
    let formattedRate = rate.map((r) => ({
      value: Number(((r.cap - 20) / 100) * icoSettings.icoTarget),
      label: `${((r.cap - 20) * icoSettings.icoTarget) / 100 / 1000000000} B`,
      cap: `${((r.cap - 20) * icoSettings.icoTarget) / 100 / 1000000000} B`
    }));
    formattedRate = formattedRate.slice(2);
    return formattedRate;
  };

  useEffect(() => {
    if (icoSettings) {
      setMarks(formatRate());
    }
    const timer = setTimeout(() => setOpenTooltip(true), 1200);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [icoSettings]);

  if (icoSettings) {
    return (
      <Box sx={{ width: '100%' }} position="relative">
        {icoSettings.rate
          .map((_, i) => ({
            sm: `${icoSettings.rate[i]?.cap - 40}%`,
            value: `$${icoSettings.rate[i - 1]?.rate}`
          }))
          .slice(2)
          .map((obj, i) => (
            <ProgressTooltip
              key={i}
              title={obj.value}
              placement="top"
              open={openTooltip}
              arrow
              sx={{ zIndex: '20 !important' }}
              className="32r23"
            >
              <Box position="absolute" left={obj.sm} top="40px" />
            </ProgressTooltip>
          ))}

        <Slider
          disabled
          size="medium"
          aria-label="Always visible"
          getAriaValueText={valuetext}
          defaultValue={Number(icoSettings.icoTarget)}
          step={1}
          marks={marks}
          valueLabelDisplay="on"
          min={0}
          max={Number(icoSettings.icoTarget)}
          sx={{
            marginTop: '20px',
            marginX: 'auto',
            display: 'block',

            '&.MuiSlider-root': {
              color: (theme) => theme.palette.primary.main
            },
            '& .MuiSlider-thumb': {
              display: 'none'
            },
            '& .MuiSlider-markLabel': {
              fontSize: 'clamp( 0.9rem, 3vw, 1.2rem)',
              color: 'black'
            }
          }}
        />
      </Box>
    );
  }

  return <Skeleton sx={{ width: '100%' }} />;
}
