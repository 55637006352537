import React, { useState, useEffect } from 'react';
// web3 utils
import Web3 from 'web3';
// moralis
import { useMoralis, useChain } from 'react-moralis';
import { motion } from 'framer-motion';
import { Outlet, useLocation } from 'react-router-dom';
// material
import { Icon } from '@iconify/react';
import { styled, useTheme, alpha } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Container, Card, Typography, Grid, CardContent, Stack, Switch } from '@mui/material';
// dayjs
import dayjs from 'dayjs';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { getIcoSettings, setIcoContractAddress, getBnbPrice } from '../../store/slices/ico';
import { getBoughtTokens, setTotalBoughtTokens, setAccountRedux } from '../../store/slices/user';
// animations
import { varWrapEnter } from '../animate';
// components
import LoadingScreen from '../LoadingScreen';

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  zIndex: 10,
  backgroundColor: theme.palette.grey[247],
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    minHeight: '100vh',
    alignItems: 'center'
  }
}));

const HeroVideoStyle = styled('video')({
  width: '100%',
  minHeight: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const GridCard = styled(Card)(({ theme }) => ({
  padding: '20px',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius
}));

const GridStack = styled(Stack)(() => ({
  padding: '40px',
  justifyContent: 'center',
  alignItems: 'center'
}));

// ----------------------------------------------------------------------

export default function LandingStaking() {
  const { pathname } = useLocation();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const pathArr = pathname.split('/');
  const vertical = 'bottom';
  const horizontal = 'center';
  const [selected, setSelected] = useState('');
  const [switchValue, setSwitchValue] = useState(false);
  const [stakingReady, setStakingReady] = useState(false);

  const {
    account: ethAccount,
    isWeb3Enabled,
    web3,
    Moralis,
    user,
    isAuthenticated,
    refetchUserData,
    enableWeb3,
    setUserData
  } = useMoralis();
  const { chainId, chain } = useChain();
  const dispatch = useDispatch();
  const { icoContractAddress, icoSettings, icoProvider, chainInfo, boughtTokensClass, icoAbi } = useSelector(
    (state) => state.ico
  );
  const { boughtTokens, account } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getIcoSettings());
    dispatch(getBnbPrice());
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (account) dispatch(getBoughtTokens(icoContractAddress, account, boughtTokensClass));
    if (!account) dispatch(getBoughtTokens('', '', boughtTokensClass));
    // console.log(icoContractAddress, account, ethAccount, user.attributes);
  }, [dispatch, icoContractAddress, account, chainId]);

  useEffect(() => {
    if (account) dispatch(setTotalBoughtTokens(boughtTokens));
    if (!account) dispatch(setTotalBoughtTokens([]));
  }, [dispatch, boughtTokens, chainId, account]);

  useEffect(() => {
    if (isWeb3Enabled) {
      (async () => {
        // const netId = await web3.eth.net.getId();
        const netId = String(chain.networkId);
        const contractAddress = icoAbi.networks[netId] ? icoAbi.networks[netId].address.toLowerCase() : undefined;
        dispatch(setIcoContractAddress(contractAddress, netId));
      })();
    }
    // eslint-disable-next-line
  }, [dispatch, isWeb3Enabled, selected]);

  useEffect(() => {
    if (chainId && chainInfo) {
      const newSelectedNetwork = chainInfo.find((item) => item.key === chainId);
      setSelected(newSelectedNetwork);
    }
  }, [chainId, chainInfo]);

  useEffect(() => {
    if (window.ethereum && isAuthenticated && icoProvider !== undefined && icoSettings) {
      enableWeb3({ provider: icoProvider, chainId: Web3.utils.hexToNumber(icoSettings.networkKey) });
      // console.log('provider set', icoProvider);
    }
    // eslint-disable-next-line
  }, [isAuthenticated, icoProvider, icoSettings]);

  const handleSwitch = async (e) => {
    await setUserData({
      useUserEthAddress: e
    });
    await refetchUserData();

    const value = !e ? ethAccount : user?.attributes?.youwhoEthAddress;
    dispatch(setAccountRedux(value));
  };

  useEffect(() => {
    if (user && ethAccount) handleSwitch(switchValue);
    // console.log(switchValue);
  }, [switchValue]);

  useEffect(() => {
    if (user && ethAccount) {
      setSwitchValue(user?.attributes?.useUserEthAddress);
      const value = !user?.attributes?.useUserEthAddress ? ethAccount : user?.attributes?.youwhoEthAddress;
      dispatch(setAccountRedux(value));
    }
    if (user && !ethAccount) {
      setSwitchValue(true);
      dispatch(setAccountRedux(user?.attributes?.youwhoEthAddress));
    }
    if (!user) {
      dispatch(setAccountRedux(''));
    }
  }, [user, dispatch, ethAccount]);

  useEffect(() => {
    if (icoSettings) {
      setStakingReady(icoSettings.stakingReady);
    }
  }, [icoSettings]);

  //   const stripStr = (str) => str.replace(/[^a-zA-Z ]/g, '');

  const checkSettings = () => {
    if (icoSettings) {
      if (!stakingReady) {
        return (
          <Stack spacing={4} p={2} sx={{ width: '100%' }} alignItems="center">
            <Box sx={{ height: { xs: 75, sm: 100 } }}>
              <img alt="YOUWHO logo" src="/static/logos/svg/youwho-teal_text-long-alt.svg" width={570} height={100} />
            </Box>

            <Typography variant="h2" fontWeight="300">
              Staking Portal Under Maintenance ...
            </Typography>
          </Stack>
        );
      }
      return <Outlet />;
    }
    return <LoadingScreen />;
  };

  return (
    <>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        <HeroVideoStyle autoPlay loop muted>
          <source src="/static/media/video/youwho-white-bg.mp4" type="video/mp4" />
        </HeroVideoStyle>
        <Container maxWidth="lg" sx={{ paddingY: '80px' }}>
          <motion.div>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={11} md={10}>
                <Card sx={{ bgcolor: alpha(theme.palette.common.white, 0.8) }}>
                  <CardContent
                    sx={{
                      padding: { xs: 0, sm: 3 },
                      paddingTop: { xs: '40px', sm: '130px' },
                      display: 'flex',
                      minHeight: '500px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundImage: "url('/static/media/img/svg/youwho-vesting_vesting-header.svg')",

                      // backgroundImage: "url('/static/media/img/svg/youwho-staking_tall-alt.svg')",
                      backgroundRepeat: 'no-repeat',
                      backgroundColor: 'transparent',
                      position: 'relative'
                    }}
                  >
                    <Stack sx={{ width: '100%' }}>
                      {user && (
                        <Stack
                          position="absolute"
                          justifyContent="center"
                          alignItems="center"
                          top="10px"
                          right="10px"
                          flexDirection="row"
                        >
                          <Typography fontWeight="500" color="white">
                            {!switchValue ? 'you' : 'who'}
                          </Typography>
                          <Switch
                            color="default"
                            disabled={!ethAccount}
                            checked={switchValue}
                            onChange={() => setSwitchValue(!switchValue)}
                          />
                        </Stack>
                      )}
                      <>{checkSettings()}</>
                    </Stack>
                  </CardContent>
                  <Stack>
                    <img alt="youwho stays" src="/static/media/img/svg/youwho-vesting-footer-01.svg" />

                    {/* <img alt="youwho eats" src="/static/media/img/svg/youwho-eats-banner-footer.svg" /> */}
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </motion.div>
        </Container>
      </RootStyle>
    </>
  );
}

// const totalSteps = () => steps.length;

// const completedSteps = () => Object.keys(completed).length;

// const isLastStep = () => activeStep === totalSteps() - 1;

// const allStepsCompleted = () => completedSteps() === totalSteps();

// const handleNext = () => {
//   const newActiveStep =
//     isLastStep() && !allStepsCompleted()
//       ? // It's the last step, but not all steps have been completed,
//         // find the first step that has been completed
//         steps.findIndex((step, i) => !(i in completed))
//       : activeStep + 1;
//   navigate(steps[newActiveStep]);
// };

// const handleBack = () => {
//   navigate(steps[(prevActiveStep) => prevActiveStep - 1]);
// };

// const handleComplete = () => {
//   const newCompleted = completed;
//   newCompleted[activeStep] = true;
//   setCompleted(newCompleted);
//   handleNext();
// };

// const handleReset = () => {
//   setActiveStep(0);
//   setCompleted({});
// };
