import React, { useState, useEffect } from 'react';
// moralis
import { useMoralis, useChain } from 'react-moralis';
import { motion } from 'framer-motion';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { scroller } from 'react-scroll';
// material
import { Icon } from '@iconify/react';
import { styled, useTheme, alpha } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box,
  Container,
  Card,
  CardHeader,
  Typography,
  Grid,
  Stepper,
  Step,
  StepButton,
  Snackbar,
  Alert,
  Button,
  CardContent,
  Link,
  Stack,
  Divider,
  Switch
} from '@mui/material';
// countdown timer
import Countdown from 'react-countdown';
// dayjs
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { getIcoSettings, setIcoContractAddress, getBnbPrice } from '../../store/slices/ico';
import { getBoughtTokens, setTotalBoughtTokens, setAccountRedux } from '../../store/slices/user';
// animations
import { varWrapEnter, varFadeInUp } from '../animate';
// components
import LoadingScreen from '../LoadingScreen';
import WhitelistIco from '../forms/WhitelistIco';
import AddToCalendar from '../AddToCalendar';
import IcoProgressBar from '../IcoProgressBar';
import Summary from './steps/ico/Summary';

dayjs.extend(duration);

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  zIndex: 10,
  backgroundColor: theme.palette.grey[247],
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    minHeight: '100vh',
    alignItems: 'center'
  }
}));

const HeroVideoStyle = styled('video')({
  width: '100%',
  minHeight: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const GridCard = styled(Card)(({ theme }) => ({
  padding: '20px',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius
}));

const GridStack = styled(Stack)(() => ({
  padding: '40px',
  justifyContent: 'center',
  alignItems: 'center'
}));

// ----------------------------------------------------------------------

const steps = ['overview', 'invest', 'summary'];

// ----------------------------------------------------------------------

export default function LandingIco() {
  const [activeStep, setActiveStep] = useState(0);
  const [icoStartDate, setIcoStartDate] = useState(new Date('Wednesday, January 19, 2022 5:00:00 AM'));
  const [referral, setReferral] = useState(undefined);
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const pathArr = pathname.split('/');
  const vertical = 'bottom';
  const horizontal = 'center';
  const [currentDate, setCurrentDate] = useState(new Date());
  const params = useParams();
  const [selected, setSelected] = useState('');
  const [switchValue, setSwitchValue] = useState(false);

  const {
    account: ethAccount,
    isWeb3Enabled,
    web3,
    user,
    isAuthenticated,
    refetchUserData,
    enableWeb3,
    setUserData
  } = useMoralis();
  const { chainId, chain } = useChain();
  const dispatch = useDispatch();
  const { icoContractAddress, icoSettings, icoProvider, chainInfo, boughtTokensClass, icoAbi } = useSelector(
    (state) => state.ico
  );
  const { boughtTokens, account } = useSelector((state) => state.user);
  const [icoEndDate, setIcoEndDate] = useState(new Date('WedSaturday, March 5, 2022 5:00:00 AM'));

  useEffect(() => {
    dispatch(getIcoSettings());
    dispatch(getBnbPrice());
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (account) dispatch(getBoughtTokens(icoContractAddress, account, boughtTokensClass));
    if (!account) dispatch(getBoughtTokens('', '', boughtTokensClass));
    // console.log(icoContractAddress, account, ethAccount, user.attributes);
  }, [dispatch, icoContractAddress, account, chainId]);

  useEffect(() => {
    if (account) dispatch(setTotalBoughtTokens(boughtTokens));
    if (!account) dispatch(setTotalBoughtTokens([]));
  }, [dispatch, boughtTokens, chainId, account]);

  // finding which step we are in the steps variable
  useEffect(() => {
    setActiveStep(steps.indexOf(pathArr[2]));
  }, [pathArr]);

  useEffect(() => {
    if (hash === '#price' && icoSettings) {
      scroller.scrollTo('price', {
        duration: 500,
        delay: 1000,
        smooth: 'easeInOutQuart',
        offset: -200
      });
    }
  }, [hash, icoSettings]);

  useEffect(() => {
    if (params) setReferral(params.referral);
  }, [params]);

  useEffect(() => {
    if (isWeb3Enabled) {
      (async () => {
        const netId = String(chain.networkId);
        const contractAddress = icoAbi.networks[netId] ? icoAbi.networks[netId].address.toLowerCase() : undefined;
        dispatch(setIcoContractAddress(contractAddress, netId));
      })();
    }
    // eslint-disable-next-line
  }, [dispatch, isWeb3Enabled, selected]);

  useEffect(() => {
    if (chainId && chainInfo) {
      const newSelectedNetwork = chainInfo.find((item) => item.key === chainId);
      setSelected(newSelectedNetwork);
    }
  }, [chainId, chainInfo]);

  const handleStep = (step) => () => {
    navigate(steps[step]);
  };

  useEffect(() => {
    if (icoSettings) {
      setIcoStartDate(new Date(icoSettings.start * 1000));
      setIcoEndDate(new Date(icoSettings.endDate * 1000));
    }
  }, [icoSettings]);

  useEffect(() => {
    if (window.ethereum && isAuthenticated && icoProvider !== undefined && icoSettings) {
      enableWeb3({ provider: icoProvider, chainId: web3.utils.hexToNumber(icoSettings.networkKey) });
      // console.log('provider set', icoProvider);
    }
    // eslint-disable-next-line
  }, [isAuthenticated, icoProvider, icoSettings]);

  const handleSwitch = async (e) => {
    await setUserData({
      useUserEthAddress: e
    });
    await refetchUserData();

    const value = !e ? ethAccount : user?.attributes?.youwhoEthAddress;
    dispatch(setAccountRedux(value));
  };

  useEffect(() => {
    if (pathArr[2] === 'invest' && pathArr[3] === 'crypto') setSwitchValue(false);
    if (pathArr[2] === 'invest' && pathArr[3] === 'card') setSwitchValue(true);
  }, [pathArr[3]]);

  useEffect(() => {
    if (user && ethAccount) handleSwitch(switchValue);
    // console.log(switchValue);
  }, [switchValue]);

  useEffect(() => {
    if (user && ethAccount) {
      setSwitchValue(user?.attributes?.useUserEthAddress);
      const value = !user?.attributes?.useUserEthAddress ? ethAccount : user?.attributes?.youwhoEthAddress;
      dispatch(setAccountRedux(value));
    }
    if (user && !ethAccount) {
      setSwitchValue(true);
      dispatch(setAccountRedux(user?.attributes?.youwhoEthAddress));
    }
    if (!user) {
      dispatch(setAccountRedux(''));
    }
  }, [user, dispatch, ethAccount]);

  const stripStr = (str) => str.replace(/[^a-zA-Z ]/g, '');

  // Renderer callback with condition
  const renderer = ({ completed, formatted: { days, hours, minutes, seconds } }) =>
    completed ? (
      // Render a completed state
      <></>
    ) : (
      // Render a countdown
      <Grid container spacing={2}>
        {[
          { time: days, word: 'days' },
          { time: hours, word: 'hours' },
          { time: minutes, word: 'mins' },
          { time: seconds, word: 'sec' }
        ].map((obj, i) => (
          <Grid item xs={6} md={3} key={i}>
            <GridCard
              sx={{
                background: 'initial'
              }}
            >
              <Typography variant="h2" fontWeight="500" color="primary.main" lineHeight="1">
                {obj.time}
              </Typography>
              <Typography variant="h5" fontWeight="300">
                {obj.word}
              </Typography>
            </GridCard>
          </Grid>
        ))}
      </Grid>
    );

  const checkSettings = () => {
    if (icoSettings) {
      if (currentDate > icoEndDate) {
        return <Summary />;
      }
      if (icoStartDate > currentDate) {
        return (
          <Stack p={{ xs: '20px', sm: '10px' }}>
            <Stack spacing={2} alignItems="center">
              <img alt="YOUWHO logo" src="/static/logos/svg/youwho-teal_text-long-alt.svg" width={570} />
              <Stack direction={smUp ? 'row' : 'column'} spacing={1}>
                <Typography variant="h2">ICO Portal</Typography>
                <Typography variant="h2" fontWeight="300">
                  opening in
                </Typography>
              </Stack>
            </Stack>
            <Box height={{ xs: '20px', sm: '40px' }} />
            <Countdown date={icoSettings.start * 1000} renderer={renderer} />
            <Box height="40px" />
            <WhitelistIco referral={referral} />
            <Box height="20px" />
            <Typography variant="h3" fontWeight={300} textAlign="center">
              and
            </Typography>
            <Box height="20px" />
            <AddToCalendar />
            <Box height="20px" />
            <Divider variant="middle" sx={{ width: '80%', mx: 'auto', my: '30px' }} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <GridStack sx={{ paddingY: 3, paddingX: { xs: '0px', sm: '20px' } }} spacing={2}>
                  <Stack direction="row" spacing={2} id="price">
                    <img alt="$YOU logo" src="/static/logos/svg/youwho-logos_token-alt.svg" width={150} />
                    <Typography variant="h2"> Pricing</Typography>
                  </Stack>
                  <Box height="20px" />
                  <IcoProgressBar />
                </GridStack>
              </Grid>
            </Grid>
            <Box height="50px" />
            <Grid container spacing={2} sx={{ padding: '20px' }}>
              <Grid item xs={12} sm={6}>
                <GridCard>
                  <Box>
                    <Icon icon="fluent:target-arrow-16-filled" color="#00c999" width="50" height="50" />
                  </Box>
                  <Typography variant="h4">ICO Target</Typography>
                  <Typography variant="h4" fontWeight="300">
                    {icoSettings.icoTarget / 1000000000} Bil. ${icoSettings.tokenSymbol}
                  </Typography>
                </GridCard>
              </Grid>

              <Grid item xs={12} sm={6}>
                <GridCard>
                  <Box>
                    <Icon icon="ion:golf" color="#00c999" width="50" height="50" />
                  </Box>
                  <Typography variant="h4">Start date</Typography>
                  <Typography variant="h4" fontWeight="300" textAlign="center">
                    {icoStartDate.toDateString()}
                  </Typography>
                </GridCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <GridCard>
                  <Box>
                    <Icon icon="ion:timer" color="#00c999" width="50" height="50" />
                  </Box>
                  <Typography variant="h4">Duration</Typography>
                  <Typography variant="h4" fontWeight="300">
                    {icoSettings.days} days
                  </Typography>
                </GridCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <GridCard>
                  <Box sx={{ p: '2.5px' }}>
                    <Icon icon="bi:shield-lock-fill" color="#00c999" width="40" height="40" />
                  </Box>
                  <Typography variant="h4">Cap per wallet</Typography>
                  <Typography variant="h4" fontWeight="300">
                    {icoSettings.capPerWallet / 1000000} Mil. ${icoSettings.tokenSymbol}
                  </Typography>
                </GridCard>
              </Grid>
            </Grid>
          </Stack>
        );
      }
      return <Outlet />;
    }
    return <LoadingScreen />;
  };

  return (
    <>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        <HeroVideoStyle autoPlay loop muted>
          <source src="/static/media/video/youwho-white-bg.mp4" type="video/mp4" />
        </HeroVideoStyle>
        <Container maxWidth="lg" sx={{ paddingY: '100px' }}>
          <motion.div>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={11} md={10}>
                <Card sx={{ bgcolor: alpha(theme.palette.common.white, 0.8) }}>
                  {icoSettings && currentDate < icoEndDate && (
                    <CardHeader
                      sx={{
                        bgcolor: (theme) => theme.palette.grey[300],
                        paddingBottom: '20px',
                        paddingTop: { xs: '12px', md: '20px' }
                      }}
                      subheader={
                        <>
                          {smDown && (
                            <Box paddingBottom="10px">
                              <Typography textAlign="center" variant="h5">
                                {steps[activeStep] ? stripStr(steps[activeStep]) : ''}
                              </Typography>
                            </Box>
                          )}
                          <Stepper nonLinear activeStep={activeStep}>
                            {steps.map((label, index) => (
                              <Step key={label}>
                                <StepButton color="inherit" onClick={handleStep(index)} disableRipple>
                                  {smUp && stripStr(label)}
                                </StepButton>
                              </Step>
                            ))}
                          </Stepper>
                        </>
                      }
                    />
                  )}
                  <CardContent
                    sx={{
                      padding: { xs: 0, sm: 3 },
                      paddingTop: { xs: '40px', sm: '130px' },
                      display: 'flex',
                      minHeight: '500px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundImage: "url('/static/media/img/svg/youwho-ico-top-bg_tall.svg')",
                      backgroundRepeat: 'no-repeat',
                      backgroundColor: 'transparent',
                      position: 'relative'
                    }}
                  >
                    <Stack sx={{ width: '100%' }}>
                      {user && (
                        <Stack
                          position="absolute"
                          justifyContent="center"
                          alignItems="center"
                          top="10px"
                          right="10px"
                          flexDirection="row"
                        >
                          <Typography fontWeight="500" color="white">
                            {!switchValue ? 'you' : 'who'}
                          </Typography>
                          <Switch
                            color="default"
                            disabled={!ethAccount}
                            checked={switchValue}
                            onChange={() => setSwitchValue(!switchValue)}
                          />
                        </Stack>
                      )}
                      <>{checkSettings()}</>
                    </Stack>
                  </CardContent>
                  <Stack>
                    <img alt="youwho programmer" src="/static/media/img/svg/youwho-workwoods-04.svg" />
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </motion.div>
        </Container>
      </RootStyle>
    </>
  );
}

// const totalSteps = () => steps.length;

// const completedSteps = () => Object.keys(completed).length;

// const isLastStep = () => activeStep === totalSteps() - 1;

// const allStepsCompleted = () => completedSteps() === totalSteps();

// const handleNext = () => {
//   const newActiveStep =
//     isLastStep() && !allStepsCompleted()
//       ? // It's the last step, but not all steps have been completed,
//         // find the first step that has been completed
//         steps.findIndex((step, i) => !(i in completed))
//       : activeStep + 1;
//   navigate(steps[newActiveStep]);
// };

// const handleBack = () => {
//   navigate(steps[(prevActiveStep) => prevActiveStep - 1]);
// };

// const handleComplete = () => {
//   const newCompleted = completed;
//   newCompleted[activeStep] = true;
//   setCompleted(newCompleted);
//   handleNext();
// };

// const handleReset = () => {
//   setActiveStep(0);
//   setCompleted({});
// };
