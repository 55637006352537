import { useState, useEffect } from 'react';
import { useMoralisQuery } from 'react-moralis';

export default function TotalSupply() {
  const [supply, setSupply] = useState(0);
  const { data } = useMoralisQuery('IcoSettings');

  useEffect(() => {
    if (data.length > 0) setSupply(Number(data[0].attributes.totalSupply));
  }, [data]);
  return <pre>{supply}</pre>;
}
