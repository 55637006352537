// react
import React from 'react';
// react-router
import { Routes, Route, Navigate } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
// components
import LandingPage from '../pages/LandingPage';
import LandingIco from '../components/landing/LandingIco';
import Overview from '../components/landing/steps/ico/Overview';
import LandingStaking from '../components/landing/LandingStaking';
import MainStaking from '../components/landing/steps/staking/MainStaking';
import LandingVesting from '../components/landing/LandingVesting';
import MainVesting from '../components/landing/steps/vesting/MainVesting';
import LandingAdmin from '../components/landing/LandingAdmin';
import AdminDashboard from '../components/landing/steps/admin/AdminDashboard';
import MaxSupply from '../components/apis/MaxSupply';
import TotalSupply from '../components/apis/TotalSupply';
import CirculatingSupply from '../components/apis/CirculatingSupply';

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Navigate to="staking/main" />} />
        <Route element={<LandingPage title="Staking | youwho" />}>
          <Route path="staking" element={<LandingStaking />}>
            <Route index element={<Navigate to="main" replace />} />
            <Route path="main" element={<MainStaking />} />
            <Route path="*" element={<Navigate to="main" replace />} />
          </Route>
        </Route>
        <Route element={<LandingPage title="Vesting | youwho" />}>
          <Route path="vesting" element={<LandingVesting />}>
            <Route index element={<Navigate to="main" replace />} />
            <Route path="main" element={<MainVesting />} />
            <Route path="*" element={<Navigate to="main" replace />} />
          </Route>
        </Route>
        {/* <Route element={<LandingPage title="ICO | youwho" />}>
          <Route path="ico" element={<LandingIco />}>
            <Route index element={<Navigate to="overview" replace />} />
            <Route path="overview" element={<Overview />} />
            <Route path="overview/:referral" element={<Overview />} />
            <Route path="*" element={<Navigate to="overview" replace />} />
          </Route>
        </Route> */}
        {/* <Route element={<LandingPage title="Admin | youwho" />}>
          <Route path="admin" element={<LandingAdmin />}>
            <Route index element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="*" element={<Navigate to="dashboard" replace />} />
          </Route>
        </Route> */}
      </Route>
      <Route path="api" element={<LandingPage title="API | youwho" />}>
        <Route index path="total-supply" element={<TotalSupply />} />
        <Route path="circulating-supply" element={<CirculatingSupply />} />
        <Route path="max-supply" element={<MaxSupply />} />
      </Route>
      <Route path="*" element={<Navigate to="/staking/main" replace />} />
      {/* <Route path="*" element={<LogoOnlyLayout />}>
        <Route path="404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} replace />
      </Route> */}
    </Routes>
  );
}
